import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { LEVELS, COMPETENCIES, NM_ANSWERS, ANSWERS } from '../data';

function groupCompetenciesByCategory(competencies) {
  const groupedCompetencies = {};

  competencies.forEach((competency) => {
    if (!groupedCompetencies[competency.category]) {
      groupedCompetencies[competency.category] = [];
    }

    groupedCompetencies[competency.category].push(competency);
  });

  return groupedCompetencies;
}

function getAnswerName(answerId, isNegativeMarker) {
  const answers = isNegativeMarker ? NM_ANSWERS : ANSWERS;

  return answers.find((a) => a.id === answerId)?.name;
}

function Results() {
  // Get the results from local storage
  const results = JSON.parse(localStorage.getItem('CMATRIX_RESULTS'));

  if (!results) {
    return (
      <div>
        <h1 className="mb-4">Results</h1>

        <p className="lead">
          You have not completed the assessment yet.
        </p>
      </div>
    );
  }

  return (
    <div>

      <h1 className="mb-4">Results</h1>

      {/* Download results button */}
      <div className="mb-5">
        <Button
          as={NavLink}
          to={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(results))}`}
          download="results.json"
          variant="outline-primary"
        >
          Download Results
        </Button>

        {/* Download list of competencies which are hard to understand */}
        <Button
          as={NavLink}
          to={`data:text/json;charset=utf-8,${encodeURIComponent(localStorage.getItem('CMATRIX_HARD_TO_UNDERSTAND'))}`}
          download="hard-to-understand.json"
          variant="outline-secondary"
          className="mx-3"
        >
          Download &quot;Hard to Understand&quot; List
        </Button>

        {/* Download notes about competencies */}
        <Button
          as={NavLink}
          to={`data:text/json;charset=utf-8,${encodeURIComponent(localStorage.getItem('CMATRIX_NOTES'))}`}
          download="notes.json"
          variant="outline-secondary"
        >
          Download Notes
        </Button>
      </div>

      {
        LEVELS.map((level, i) => (
          <React.Fragment key={i}>
            <div className="border-start border-warning border-3 px-3 mb-5 mt-5 pb-4 pt-4">
              <h2>{level.name}</h2>

              <p className="lead">
                {level.description}
              </p>

              <ul>
                {
                level.requirements.map((requirement, j) => (
                  <li key={j}>{requirement}</li>
                ))
              }
              </ul>

            </div>

            <div className="mb-5">
              {
              Object.entries(groupCompetenciesByCategory(COMPETENCIES.filter((c) => c.level === level.id))).map(([category, competencies]) => (
                <div className="mb-5" key={category}>
                  <h3>{category}</h3>

                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">Competency</th>
                        <th className="text-center" style={{ width: '15%' }}>Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        competencies.map((competency, k) => (
                          <tr key={k}>
                            <td>
                              <strong>{`[${competency.id}] ${competency.name}`}</strong>
                              <br />
                              <br />
                              <small style={{ whiteSpace: 'pre-line' }}>{competency.description}</small>
                            </td>
                            <td className={`text-center ${results[competency.id]}`}>
                              {
                                getAnswerName(results[competency.id], competency.categoryId === 'NM')
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ))
            }
            </div>
          </React.Fragment>
        ))
      }
    </div>
  );
}

export default Results;
