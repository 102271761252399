import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { LEVELS, COMPETENCIES } from '../data';

function calculateRatedCompetenciesPercent(competencies) {
  let answeredCompetencies = 0;

  // Get the results from local storage
  const results = JSON.parse(localStorage.getItem('CMATRIX_RESULTS'));

  competencies.forEach((competency) => {
    if (results[competency.id]) {
      answeredCompetencies += 1;
    }
  });

  return Math.round((answeredCompetencies / competencies.length) * 100);
}

function Competencies() {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!localStorage.getItem('CMATRIX_RESULTS')) {
    // Redirect to the start page
      navigate('/');
    }
  }, []);

  return (
    <>
      <h1 className="mb-4">Уровни компетенций</h1>

      <p className="lead mb-5">
        Каждый уровень ВКЛЮЧАЕТ в себя все требования предыдущего уровня. Именно поэтому необходимо
        начинать с самого низкого уровня и продвигаться по уровням вверх.
      </p>

      {
        LEVELS.map((level, index) => (
          <div className="border-start border-warning border-3 px-3 mb-4" key={index}>
            <h2>{level.name}</h2>

            <p className="lead">
              {level.description}
            </p>

            <ul>
              {
                level.requirements.map((requirement, requirementIndex) => (
                  <li key={requirementIndex}>{requirement}</li>
                ))
              }
            </ul>

            <ProgressBar
              className="mb-3"
              now={calculateRatedCompetenciesPercent(COMPETENCIES.filter((q) => q.level === level.id))}
              label={`${calculateRatedCompetenciesPercent(COMPETENCIES.filter((q) => q.level === level.id))}%`}
            />

            <Button variant="outline-primary" as={NavLink} to={`/competencies/${level.id}`}>Go to Competencies</Button>
          </div>
        ))
      }
    </>
  );
}

export default Competencies;
