import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { CATEGORIES, LEVELS, COMPETENCIES, NM_ANSWERS, ANSWERS } from '../data';

function generateCompetencyURL(competency) {
  return `/competencies/${competency.level}/${competency.categoryId}/${competency.id}`;
}

function LevelButtons({ currentAnswer, onClick, isNegativeMarker }) {
  console.log(isNegativeMarker);

  const answers = isNegativeMarker ? NM_ANSWERS : ANSWERS;
  if (currentAnswer) {
    return (
      // Render the same ButtonGroup but with outline buttons and the selected button highlighted
      <ButtonGroup>
        {
          answers.map((answer) => (
            <Button
              key={answer.id}
              variant={`${currentAnswer === answer.id ? answer.id : `outline-${answer.id}`}`}
              onClick={() => onClick(answer.id)}
            >
              {answer.description}
            </Button>
          ))
        }
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup>
      {
        answers.map((answer) => (
          <Button
            key={answer.id}
            variant={answer.id}
            onClick={() => onClick(answer.id)}
          >
            {answer.description}
          </Button>
        ))
      }
    </ButtonGroup>
  );
}

LevelButtons.propTypes = {
  currentAnswer: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isNegativeMarker: PropTypes.bool.isRequired,
};

LevelButtons.defaultProps = {
  currentAnswer: null,
};

function Competency() {
  const { levelId, categoryId, competencyId } = useParams();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    competency: null,
    category: null,
    answer: null,
    nextCompetency: null,
    prevCompetency: null,
    isHardToUnderstand: false,
    notes: '',
    isNegativeMarker: false,
  });

  React.useEffect(() => {
    // Redirect to the start page if no results are found in local storage
    if (!localStorage.getItem('CMATRIX_RESULTS')) {
      console.log('No results found in local storage. Redirecting to start page...');
      navigate('/');
      return;
    }

    console.log([levelId, categoryId, competencyId]);

    // Redirect to the first competency if no competency ID is found
    if (!competencyId) {
      const firstCompetency = COMPETENCIES.filter((competency) => competency.level === levelId)[0];

      if (firstCompetency) {
        // Redirect to the first competency
        navigate(generateCompetencyURL(firstCompetency));
      }
    }

    const level = LEVELS.find((level) => level.id === levelId);
    const category = CATEGORIES.find((category) => category.id === categoryId);
    const competency = COMPETENCIES.find((competency) => competency.id === competencyId);
    const isHardToUnderstand = JSON.parse(localStorage.getItem('CMATRIX_HARD_TO_UNDERSTAND')).includes(competencyId);
    const notes = JSON.parse(localStorage.getItem('CMATRIX_NOTES'))[competencyId] || '';
    const index = COMPETENCIES.findIndex((competency) => competency.id === competencyId);

    setState({
      ...state,
      competency,
      level,
      category,
      answer: JSON.parse(localStorage.getItem('CMATRIX_RESULTS'))[competencyId],
      nextCompetency: COMPETENCIES[index + 1],
      prevCompetency: COMPETENCIES[index - 1],
      isHardToUnderstand,
      notes,
      isNegativeMarker: competency && competency.categoryId === 'NM',
    });
  }, [levelId, categoryId, competencyId]);

  const handleAnswerClick = (type) => {
    // Get the results from local storage
    const results = JSON.parse(localStorage.getItem('CMATRIX_RESULTS'));

    // Add the answer to the results
    results[competencyId] = type;

    // Save the results back to local storage
    localStorage.setItem('CMATRIX_RESULTS', JSON.stringify(results));

    // Redirect to the next competency or the results page
    if (!state.nextCompetency) {
      navigate('/results');
    } else {
      navigate(generateCompetencyURL(state.nextCompetency));
    }
  };

  const handleHardToUnderstandChange = (event) => {
    // If checkbox is checked, add the competency ID to the hard to understand array
    if (event.target.checked) {
      const hardToUnderstand = JSON.parse(localStorage.getItem('CMATRIX_HARD_TO_UNDERSTAND'));

      if (!hardToUnderstand.includes(competencyId)) {
        hardToUnderstand.push(competencyId);
        localStorage.setItem('CMATRIX_HARD_TO_UNDERSTAND', JSON.stringify(hardToUnderstand));
      }
    } else {
      // If checkbox is unchecked, remove the competency ID from the hard to understand array
      const hardToUnderstand = JSON.parse(localStorage.getItem('CMATRIX_HARD_TO_UNDERSTAND'));
      const index = hardToUnderstand.findIndex((id) => id === competencyId);
      hardToUnderstand.splice(index, 1);
      localStorage.setItem('CMATRIX_HARD_TO_UNDERSTAND', JSON.stringify(hardToUnderstand));
    }

    setState({
      ...state,
      isHardToUnderstand: event.target.checked,
    });
  };

  const handleNotesChange = (event) => {
    const { value } = event.target;

    // Get the notes from local storage
    const notes = JSON.parse(localStorage.getItem('CMATRIX_NOTES'));

    if (value) {
      // Add the notes to the notes object
      notes[competencyId] = event.target.value;
    } else {
      // Remove the notes from the notes object
      delete notes[competencyId];
    }

    // Save the notes back to local storage
    localStorage.setItem('CMATRIX_NOTES', JSON.stringify(notes));

    setState({
      ...state,
      notes: event.target.value,
    });
  };

  if (!state.competency) {
    return (
      <Alert variant="danger">
        Competency not found
      </Alert>
    );
  }

  return (
    <div>

      <div className={`border-start border-5 ${state.isNegativeMarker ? 'border-danger' : 'border-primary'} p-3 mb-4`}>

        <small>
          <strong>Level: </strong>
          {state.level.name}
        </small>

        <br />

        <small>
          <strong>Category:</strong>
          {' '}
          {state.category.name}
        </small>

        <hr />

        <p className="lead">
          <strong>
            {`[${state.competency.id}]`}
          </strong>
          <br />
          {' '}
          {state.competency.name}
        </p>

        <p style={{ whiteSpace: 'pre-line' }}>
          {state.competency.description}
        </p>

        {/* Checkbox to mark competency as hard to understand */}
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={state.isHardToUnderstand}
            onChange={handleHardToUnderstandChange}
            id="hardToUnderstand"
          />
          <label className="form-check-label" htmlFor="hardToUnderstand">
            Mark this competency as hard to understand
          </label>
        </div>

        {/* Notes field */}
        <div className="mt-3">
          <label htmlFor="notes" className="form-label">
            Notes (optional)
          </label>
          <textarea
            className="form-control"
            id="notes"
            rows="3"
            value={state.notes}
            onChange={handleNotesChange}
          />
          <small>
            Here you can add any notes you have about this competency.
          </small>
        </div>

      </div>

      <div className="d-flex justify-content-between mt-4 mb-4">

        <LevelButtons
          currentAnswer={state.answer}
          onClick={handleAnswerClick}
          isNegativeMarker={state.competency.categoryId === 'NM'}
        />

        <div>
          {
            state.prevCompetency && (
              <Button
                as={NavLink}
                to={generateCompetencyURL(state.prevCompetency)}
                variant="outline-primary"
                className="me-2"
              >
                &larr; Previous
              </Button>
            )
          }

          {
            state.nextCompetency && (
              <Button
                variant="outline-primary"
                as={NavLink}
                to={generateCompetencyURL(state.nextCompetency)}
                className="mx-2"
              >
                Next &rarr;
              </Button>
            )
          }

          {
            !state.nextCompetency && (
              <Button as={NavLink} to="/results" variant="outline-success">Aaaand... Finish!</Button>
            )
          }
        </div>

      </div>

      {
        state.isNegativeMarker && (
        <Alert variant="danger" className="mt-4">
          <Alert.Heading>Negative Marker</Alert.Heading>
          <p>
            This competency is a negative marker. This means that there is a bit different
            approach to answering this competency. Check description below for more information.
          </p>
        </Alert>
        )
      }

      <Alert variant="info" className="mt-4">
        <Alert.Heading>Competency Levels</Alert.Heading>
        <ul>
          {
            state.isNegativeMarker
              ? (
                NM_ANSWERS.map((answer) => (
                  <li key={answer.id}>
                    <strong>{answer.name}</strong>
                    :
                    {' '}
                    {answer.description}
                  </li>
                ))
              )
              : (
                ANSWERS.map((answer) => (
                  <li key={answer.id}>
                    <strong>{answer.name}</strong>
                    :
                    {' '}
                    {answer.description}
                  </li>
                ))
              )
          }

        </ul>
      </Alert>
    </div>
  );
}

export default Competency;
