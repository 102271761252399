import { useEffect, useState } from 'react';
import { logout, sendRequest, useAuth } from './utils';

// eslint-disable-next-line import/prefer-default-export
export function useUser() {
  const [logged] = useAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Load user infromation from the server
    if (logged) {
      sendRequest({
        route: 'users',
        method: 'GET',
        onSuccess: (user) => { setUser(user); },
        onError: () => { logout(); setUser(null); localStorage.clear(); },
      });
    }
  }, [logged]);

  return user;
}
