import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from 'react-bootstrap';

import './App.css';

import NotFound from './Common/404';
import ErrorFallback from './Common/ErrorFallback';
import Menu from './Common/Menu';
import PrivateRoute from './Common/PrivateRoute';
import Start from './Pages/Start';
import Competency from './Pages/Competency';
import Results from './Pages/Results';
import Competencies from './Pages/Competencies';
import ReviewResults from './Pages/ReviewResults';
import Gerchikov from './Pages/Gerchikov';
import DISC from './Pages/DISC';
import Login from './Pages/Auth/Login';
import { useAuth } from './utils';

function App() {
  const [logged] = useAuth();

  return (
    <BrowserRouter>
      <Menu />
      <Container className="mt-5 mb-5">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route exact path="disc" element={<DISC />} />
            <Route exact path="gerchikov" element={<Gerchikov />} />

            <Route path="" element={<PrivateRoute logged={logged} />}>

              <Route exact path="competencies" element={<Competencies />} />
              <Route exact path="competencies/:levelId" element={<Competency />} />
              <Route exact path="competencies/:levelId/:categoryId" element={<Competency />} />
              <Route exact path="competencies/:levelId/:categoryId/:competencyId" element={<Competency />} />

              <Route exact path="results" element={<Results />} />
              <Route exact path="review" element={<ReviewResults />} />
              <Route exact path="" element={<Start />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </Container>
    </BrowserRouter>
  );
}

export default App;
