import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';

function Start() {
  console.log('Start page');
  const navigate = useNavigate();

  const handleStartClick = () => {
    if (!localStorage.getItem('CMATRIX_RESULTS')) {
      // Create an empty results array in local storage
      localStorage.setItem('CMATRIX_RESULTS', JSON.stringify({}));
      localStorage.setItem('CMATRIX_HARD_TO_UNDERSTAND', JSON.stringify([]));
      localStorage.setItem('CMATRIX_NOTES', JSON.stringify({}));
    }

    // Redirect to the first competency
    navigate('/competencies');
  };

  const handleResetClick = () => {
    // Remove the results from local storage
    localStorage.removeItem('CMATRIX_RESULTS');
    localStorage.removeItem('CMATRIX_HARD_TO_UNDERSTAND');
    localStorage.removeItem('CMATRIX_NOTES');

    // Redirect to the start page
    navigate('/');
  };

  return (
    <>
      <h1 className="mb-4">Начальная страница</h1>

      <div className="border-start border-4 border-primary px-3 pt-2 pb-2 mb-4">
        <p>Шаг 1. Тест #1</p>

        <Button as={NavLink} to="/gerchikov" variant="outline-primary">Пройти Тест #1</Button>
      </div>

      <div className="border-start border-4 border-primary px-3 pt-2 pb-2 mb-4">
        <p>Шаг 2. Тест #2</p>

        <Button as={NavLink} to="/disc" variant="outline-primary">Пройти Тест #2</Button>
      </div>

      <div className="border-start border-4 border-primary px-3 pt-2 pb-2">

        <p>Шаг 3. Матрица компетенций</p>

        <Button variant="outline-primary" onClick={handleStartClick}>Start Answering</Button>
        <Button as={NavLink} to="/results" variant="outline-primary" className="mx-3">View Results</Button>

        {
          localStorage.getItem('CMATRIX_RESULTS') && (
            <Button variant="outline-danger" className="mx-3" onClick={handleResetClick}>Reset Answers</Button>
          )
        }
      </div>
    </>
  );
}

export default Start;
