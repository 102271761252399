import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import logo from '../logo.svg';
import { useUser } from '../hooks';
import { logout } from '../utils';

function Menu() {
  const user = useUser();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top me-3"
          />
          Competency Matrix
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="main-navbar-nav" />
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="me-auto">
            {/* {user && (
              <Nav.Link as={NavLink} to="admin">Admin</Nav.Link>
            )} */}
          </Nav>

          <Nav>
            {user ? (
              <NavDropdown title={`${user.first_name} ${user.last_name} (${user.email})`} id="actions-nav-dropdown" className="d-flex">
                <NavDropdown.Item onClick={() => { logout(); localStorage.clear(); }}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link as={NavLink} to="login">Login</Nav.Link>
            )}
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;
