/* eslint-disable max-len */
import React from 'react';
import { Alert, Button, Col, Form, Row, Table } from 'react-bootstrap';

// http://zdorovie-seti.moy.su/test_trudovaja_motivacija.pdf
const QUESTIONS = [
  {
    id: 1,
    question: 'Что Вы больше всего цените в своей работе? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Что я в основном сам решаю, что и как мне делать',
      'Что она дает мне возможность проявить то, что я знаю и умею',
      'Что я чувствую себя полезным и нужным',
      'Что мне за нее относительно неплохо платят',
      'Особенно ничего не ценю, но эта работа мне хорошо знакома и привычна',
    ],
  },
  {
    id: 2,
    question: 'Какое выражение из перечисленных ниже Вам подходит более всего?',
    answers: [
      'Я могу обеспечить своим трудом себе и своей семье приличный доход',
      'В своей работе я полный хозяин',
      'У меня достаточно знаний и опыта, чтобы справиться c любыми трудностями в моей работе',
      'Я ценный, незаменимый для организации работник',
      'Я всегда выполняю то, что от меня требуют',
    ],
  },
  {
    id: 3,
    question: 'Как Вы предпочитаете работать? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Предпочитаю делать то, что знакомо, привычно',
      'Нужно, чтобы в работе постоянно появлялось что-то новое, чтобы, но стоять на месте',
      'Чтобы было точно известно, что нужно сделать и что я за это получу',
      'Предпочитаю работать под полную личную ответственность',
      'Готов(а) делать все, что нужно для организации',
    ],
  },
  {
    id: 4,
    question: 'Допустим, что Вам предлагают другую работу в Вашей организации. При каких условиях Вы бы на это согласились? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Если предложат намного более высокую зарплату',
      'Если другая работа будет более творческой, интересней, чем нынешняя',
      'Если новая работа даст мне больше самостоятельности',
      'Если это очень нужно для организации',
      'При всех случаях я предпочел (предпочла) бы остаться на той работе, к которой привык (привыкла)',
    ],
  },
  {
    id: 5,
    question: 'Попробуйте определить, что для Вас означает Ваш заработок? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Плата за время и усилия, потраченные на выполнение работы',
      'Это, прежде всего, плата за мои знания, квалификацию',
      'Оплата за общие результаты деятельности организации',
      'Мне нужен гарантированный заработок - пусть небольшой, но чтобы он был',
      'Какой бы он ни был, я его заработал(а) сам(а)',
    ],
  },
  {
    id: 6,
    question: 'Как Вы относитесь к следующему источнику дохода - Заработная плата и премии? Дайте только ОДИН ответ.',
    answers: [
      'Очень важно',
      'Не очень важно',
      'Совсем не важно',
    ],
  },
  {
    id: 7,
    question: 'Как Вы относитесь к следующему источнику дохода - Доплаты за квалификацию? Дайте только ОДИН ответ.',
    answers: [
      'Очень важно',
      'Не очень важно',
      'Совсем не важно',
    ],
  },
  {
    id: 8,
    question: 'Как Вы относитесь к следующему источнику дохода - Доплаты за тяжелые и вредные условия работы? Дайте только ОДИН ответ.',
    answers: [
      'Очень важно',
      'Не очень важно',
      'Совсем не важно',
    ],
  },
  {
    id: 9,
    question: 'Как Вы относитесь к следующему источнику дохода - Социальные выплаты и льготы, пособия? Дайте только ОДИН ответ.',
    answers: [
      'Очень важно',
      'Не очень важно',
      'Совсем не важно',
    ],
  },
  {
    id: 10,
    question: 'Как Вы относитесь к следующему источнику дохода - Доходы от капитала, акций? Дайте только ОДИН ответ.',
    answers: [
      'Очень важно',
      'Не очень важно',
      'Совсем не важно',
    ],
  },
  {
    id: 11,
    question: 'Как Вы относитесь к следующему источнику дохода - Любые дополнительные приработки? Дайте только ОДИН ответ.',
    answers: [
      'Очень важно',
      'Не очень важно',
      'Совсем не важно',
    ],
  },
  {
    id: 12,
    question: 'Как Вы относитесь к следующему источнику дохода - Приработки, но не любые, а только по своей специальности? Дайте только ОДИН ответ.',
    answers: [
      'Очень важно',
      'Не очень важно',
      'Совсем не важно',
    ],
  },
  {
    id: 13,
    question: 'На каких принципах, по-Вашему, должны строиться отношения между работником и организацией? Дайте ТОЛЬКО ОДИН ответ:',
    answers: [
      'Работник должен относиться к организации, как к своему дому, отдавать ей все и вместе переживать трудности и подъемы. Организация должна соответственно оценивать преданность и труд работника',
      'Работник продает организации свой труд, и если ему не дают хорошую цену, он вправе найти другого покупателя',
      'Работник приходит в организацию для самореализации и относится к ней, как к месту реализации своих способностей. Организация должна обеспечивать работнику такую возможность, извлекать из этого выгоду для себя и на этой основе развиваться',
      'Работник тратит на организацию свои силы, а организация должна взамен гарантировать ему зарплату и социальные блага',
    ],
  },
  {
    id: 14,
    question: 'Как Вы считаете, почему в процессе работы люди проявляют инициативу, вносят различные предложения? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Чувствуют особую ответственность за свою работу',
      'Из-за стремления реализовать свои знания и опыт, выйти за установленные работой рамки',
      'Чаще всего из-за желания улучшить работу своей организации',
      'Просто хотят «выделиться» или завоевать расположение начальства',
      'Хотят заработать, поскольку всякая полезная инициатива должна вознаграждаться',
    ],
  },
  {
    id: 15,
    question: 'Какое суждение о коллективной работе Вам ближе? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Коллектив для меня очень важен, одному хороших результатов не добиться',
      'Предпочитаю работать автономно, но чувствую себя так же хорошо, когда работаю вместе с интересными людьми',
      'Мне нужна свобода действий, а коллектив чаще всего эту свободу ограничивает',
      'Можно работать и в коллективе, но платить должны по личным результатам',
      'Мне нравится работать в коллективе, так как там я среди своих',
    ],
  },
  {
    id: 16,
    question: 'Представьте себе, что у вас появилась возможность стать владельцем Вашей организации. Воспользуетесь ли Вы этой возможностью? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Да, так как я смогу участвовать в управлении организацией',
      'Да, потому что это может увеличить мой доход',
      'Да, так как настоящий работник должен быть совладельцем',
      'Вряд ли: на заработке это не скажется участие в управлении меня не интересует, а работе это помешает',
      'Нет, не нужны мне лишние заботы',
    ],
  },
  {
    id: 17,
    question: 'Представьте, пожалуйста, что Вы сейчас ищете работу. Вам предлагают несколько работ. Какую из них Вы выберете? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Наиболее интересную, творческую',
      'Наиболее самостоятельную, независимую',
      'За которую больше платят',
      'Чтобы за не слишком большие деньги не требовалось особенно «надрываться»',
      'Не могу представить, что я уйду из нашей организации',
    ],
  },
  {
    id: 18,
    question: 'Что Вы, прежде всего, учитываете, когда оцениваете успехи другого работника а Вашей организации? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Его зарплату, доходы, материальное положение',
      'Уровень его профессионализма, квалификации',
      'Насколько хорошо он «устроился»',
      'Насколько его уважают в организации',
      'Насколько он самостоятелен, независим',
    ],
  },
  {
    id: 19,
    question: 'Если положение в Вашей организации ухудшится, на какие перемены в Вашей работе и положении Вы согласитесь ради того, чтобы остаться на работе? Можете дать СКОЛЬКО УГОДНО ответов',
    answers: [
      'Освоить новую профессию',
      'Работать неполный рабочий день или перейти на менее квалифицированную работу и меньше получать',
      'Перейти на менее удобный режим работы',
      'Работать более интенсивно',
      'Соглашусь просто терпеть, потому что деваться некуда',
      'Скорее всего, я просто уйду из этой организации',
    ],
  },
  {
    id: 20,
    question: 'Если Вы - РУКОВОДИТЕЛЬ, то что Вас привлекает, прежде всего, в этом положении? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Возможность принимать самостоятельные, ответственные решения',
      'Возможность принести наибольшую пользу организации',
      'Высокий уровень оплаты',
      'Возможность организовывать работу других людей',
      'Возможность наилучшим образом применить свои знания и умения',
      'Ничего особенно не привлекает, за положение руководителя не держусь',
    ],
  },
  {
    id: 21,
    question: 'Если Вы НЕ ЯВЛЯЕТЕСЬ РУКОВОДИТЕЛЕМ, то хотели бы Вы им стать? Можете дать ОДИН ИЛИ ДВА варианта ответа:',
    answers: [
      'Да, поскольку это даст возможность принимать самостоятельные, ответственные решения',
      'Не против, если это нужно для пользы дела',
      'Да, так как при этом я смогу лучше применить свои знания и умения',
      'Да, если это будет должным образом оплачиваться',
      'Нет, профессионал может отвечать только за самого себя',
      'Нет, руководство меня не привлекает, а хорошо заработать я могу и на своем месте',
      'Да, чем я хуже других?',
      'Нет, это слишком большая нагрузка для меня',
    ],
  },

];

const ANSWERS = {
  1: ['ХО', 'ПР', 'ПА', 'ИН', 'ЛЮ'],
  2: ['ИН', 'ХО', 'ПР', 'ПА', 'ЛЮ'],
  3: ['ЛЮ', 'ПР', 'ИН', 'ХО', 'ПА'],
  4: ['ИН', 'ПР', 'ХО', 'ПА', 'ЛЮ'],
  5: ['ИН', 'ПР', 'ПА', 'ЛЮ', 'ХО'],
  6: [null, null, null, null, null],
  7: [['ИН', 'ПР'], null, ['ПА', 'ХО']],
  8: [['ИН', 'ЛЮ'], null, ['ПР', 'ПА']],
  9: ['ЛЮ', null, 'ПР'],
  10: ['ХО', null, ['ИН', 'ПР', 'ПА', 'ЛЮ']],
  11: [['ИН', 'ЛЮ'], null, 'ПА'],
  12: [['ИН', 'ПР'], null, ['ХО', 'ЛЮ']],
  13: [['ПА', 'ХО'], 'ИН', 'ПР', 'ЛЮ'],
  14: ['ХО', 'ПР', 'ПА', 'ЛЮ', 'ИН'],
  15: ['ПА', 'ПР', 'ХО', 'ИН', 'ЛЮ'],
  16: [['ПА', 'ХО'], 'ИН', 'ХО', 'ПР', 'ЛЮ'],
  17: ['ПР', 'ХО', 'ИН', 'ЛЮ', 'ПА'],
  18: ['ИН', 'ПР', 'ЛЮ', 'ПА', 'ХО'],
  19: [['ПР', 'ПА', 'ХО'], ['ПА', 'ХО', 'ЛЮ'], null, ['ИН', 'ПР', 'ПА', 'ХО'], ['ПА', 'ЛЮ'], 'ИН', 'ПР'],
  20: ['ХО', 'ПА', 'ИН', 'ЛЮ', 'ПР', 'ИН'],
  21: ['ХО', 'ПА', ['ПР', 'ХО'], 'ИН', 'ПР', 'ИН', 'ЛЮ', 'ЛЮ'],
};

const calculateResults = (answers) => {
  const results = {};

  // Calculate results for each question based on ANSWERS object
  Object.entries(answers).forEach(([questionId, answerIndexes]) => {
    answerIndexes.forEach((answerIndex) => {
      const questionResult = ANSWERS[questionId][answerIndex];

      if (Array.isArray(questionResult)) {
        questionResult.forEach((result) => {
          results[result] = (results[result] || 0) + 1;
        });
      } else if (questionResult) {
        results[questionResult] = (results[questionResult] || 0) + 1;
      }
    });
  });
  return results;
};

function Gerchikov() {
  const [answers, setAnswers] = React.useState({});
  const [finished, setFinished] = React.useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(-1);

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    const question = QUESTIONS[questionIndex];

    if (value) {
      setAnswers({
        ...answers,
        [question.id]: [...(answers[question.id] || []), answerIndex],
      });
    } else {
      setAnswers({
        ...answers,
        [question.id]: (answers[question.id] || []).filter((index) => index !== answerIndex),
      });
    }
  };

  return (
    <div>
      <h1>Тест Герчикова</h1>

      {
        currentQuestionIndex < 0 && (
          <>
            <p>
              Внимательно прочитайте вопрос и все варианты ответов, которые для него приведены.
              В каждом вопросе есть указание, сколько вариантов ответа вы можете дать.
              Большинство вопросов требуют дать только один или два варианта ответа.
            </p>

            <Alert variant="warning">
              Дорогие коллеги, мы просим вас пройти этот тест и отвечать на вопросы честно и открыто.
              Помните, что нам важны лишь агрегированные результаты, а не конкретные ответы на вопросы
              (они хранятся в вашем браузере и только на время выполнения тесты).
              Честные ответы помогут нам лучше понять динамику нашей команды и сделать сотрудничество
              еще более продуктивным. Ваша откровенность позволит нам вместе расти и развиваться,
              создавая благоприятную атмосферу для каждого из нас. Спасибо за ваше участие!
            </Alert>

            <Button onClick={() => setCurrentQuestionIndex(0)}>Начать</Button>
          </>
        )
      }

      {
        currentQuestionIndex >= 0 && !finished && (
          <>
            <p className="lead">
              {`[${currentQuestionIndex + 1}/${QUESTIONS.length}] ${QUESTIONS[currentQuestionIndex].question}`}
            </p>

            {QUESTIONS[currentQuestionIndex].answers.map((answer, index) => (
              <Form.Group className="mb-3" controlId={`${currentQuestionIndex}-${index}`} key={`${currentQuestionIndex}-${index}`}>
                <Form.Check
                  type="checkbox"
                  label={answer}
                  onChange={(e) => handleAnswerChange(currentQuestionIndex, index, e.target.checked)}
                  defaultChecked={answers[QUESTIONS[currentQuestionIndex].id]?.includes(index)}
                />
              </Form.Group>
            ))}

            {/* Buttons */}
            <div className="d-flex justify-content-between mt-4">
              <Button
                variant="secondary"
                onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
                disabled={currentQuestionIndex === 0}
              >
                Назад
              </Button>

              {
                currentQuestionIndex !== QUESTIONS.length - 1 && (
                <Button
                  variant="primary"
                  onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
                  disabled={currentQuestionIndex === QUESTIONS.length - 1}
                >
                  Далее
                </Button>
                )
              }

              {
                currentQuestionIndex === QUESTIONS.length - 1 && (
                <Button
                  variant="primary"
                  onClick={() => setFinished(true)}
                >
                  Завершить
                </Button>
                )
              }
            </div>
          </>
        )
      }

      {
        finished && (
          <>
            <h2>Результаты</h2>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Вариант</th>
                  <th>Количество</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(calculateResults(answers)).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <p>
              <Button
                variant="primary"
                onClick={() => {
                  const element = document.createElement('a');
                  const file = new Blob([JSON.stringify(calculateResults(answers))], { type: 'text/plain' });
                  element.href = URL.createObjectURL(file);
                  element.download = 'results.json';
                  document.body.appendChild(element);
                  element.click();
                }}
              >
                Скачать
              </Button>
            </p>

            <h4 className="mb-3">Характеристика типов трудовой мотивации:</h4>

            <Row>
              <Col>
                <h5>Люмпенизированный тип</h5>

                <ul>
                  <li>все равно, какую работу выполнять, нет предпочтений;</li>
                  <li>согласен на низкую оплату, при условии, чтобы другие не получали больше;</li>
                  <li>низкая квалификация;</li>
                  <li>не стремится повысить квалификацию, противодействует этому;</li>
                  <li>низкая активность и выступление против активности других;</li>
                  <li>низкая ответственность, стремление переложить ее на других;</li>
                  <li>стремление к минимизации усилий.</li>
                </ul>
              </Col>

              <Col>
                <h5>Инструментальный тип</h5>

                <ul>
                  <li>интересует цена труда, а не его содержание (то есть труд является инструментом для удовлетворения других потребностей, отсюда и название этого типа мотивации);</li>
                  <li>важна обоснованность цены, не желает &quot;подачек&quot;;</li>
                  <li>важна способность обеспечить свою жизнь самостоятельно.</li>
                </ul>
              </Col>

              <Col>
                <h5>Профессиональный тип</h5>

                <ul>
                  <li>интересует содержание работы;</li>
                  <li>не согласен на неинтересные для него работы сколько бы за них не платили;</li>
                  <li>интересуют трудные задания - возможность самовыражения;</li>
                  <li>считает важной свободу в оперативных действиях;</li>
                  <li>важно профессиональное признание, как лучшего в профессии.</li>
                </ul>
              </Col>

              <Col>
                <h5>Патриотический тип</h5>

                <ul>
                  <li>необходима идея, которая будет им двигать;</li>
                  <li>важно общественное признание участия в успехе;</li>
                  <li>главная награда - всеобщее признание незаменимости в фирме.</li>
                </ul>
              </Col>

              <Col>
                <h5>Хозяйский тип</h5>

                <ul>
                  <li>добровольно принимает на себя ответственность;</li>
                  <li>характеризуется обостренным требованием свободы действий;</li>
                  <li>не терпит контроля.</li>
                </ul>
              </Col>
            </Row>
          </>
        )
      }

    </div>
  );
}

export default Gerchikov;
