import React from 'react';
import PropTypes from 'prop-types';

import { NavLink, Outlet } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';

function PrivateRoute({ logged, children }) {
  if (!logged) {
    console.log('Not logged');
    // Render message and button to login
    return (
      <Alert variant="danger">
        <Alert.Heading className="mb-4">You need to login to access this page</Alert.Heading>

        <Button as={NavLink} to="/login" variant="outline-danger">
          Login now
        </Button>
      </Alert>
    );
  }

  return children || <Outlet />;
}

PrivateRoute.propTypes = {
  logged: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

PrivateRoute.defaultProps = {
  children: null,
};

export default PrivateRoute;
