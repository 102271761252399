export const LEVELS = [
  {
    "id": "INTERN",
    "name": "What things INTERN developer should be able to do?",
    "description": "Interns usually do not have any expirience in programming. They finished computer science courses and search for a first job",
    "requirements": [
      "Нет опыта",
      "Имеет базовые знания одного языка программирования",
      "Знает основы computer science",
      "Интересуется новыми технологиями"
    ]
  },
  {
    "id": "JUNIOR",
    "name": "What things JUNIOR developer should be able to do?",
    "description": "Junior developers, in general, do the most simple tasks or tasks with low impact. They don’t do any design of architecture",
    "requirements": [
      "Меньше года опыта",
      "Обучается и узнает новое",
      "Умеет изучать новые инструменты и технологии",
      "Знает основы computer science",
      "Знает как минимум однин язык программирования",
      "Учится на своих ошибках"
    ]
  },
  {
    "id": "MIDDLE",
    "name": "What things MIDDLE developer should be able to do?",
    "description": "Mid-level developers are not designing solutions either, they just perform tasks. They perform those tasks with less supervision as long as they are assigned to relatively routine tasks",
    "requirements": [
      "Больше 2 лет опыта",
      "Обучается и узнает новое",
      "Имеет опыт работы на нескольких проектах",
      "Может работать самостоятельно и в команде",
      "Может менторить (делать ревью) junior разработчиков",
      "Умеет нести ответственность за принятые решения"
    ]
  },
  {
    "id": "SENIOR",
    "name": "What things SENIOR developer should be able to do?",
    "description": "Senior developers can develop an application completely on their own",
    "requirements": [
      "Больше 4 лет опыта",
      "При принятии решений ориентируется на конечную цель бизнеса",
      "Мыслит в масштабе проекта, не конкретных задач",
      "Менторит junior / middle разработчиков",
      "Помогает команде принимать решения",
      "Умеет делегировать задачи",
      "Участвует в принятии архитектурных решений (включая интеграционные задачи)",
      "Закрывает задачи от А до Я"
    ]
  },
  {
    "id": "LEAD",
    "name": "What things LEAD developer should be able to do?",
    "description": "Lead developers direct and guide the application team in design, coding, testing, researching & analyzing developments",
    "requirements": [
      "Больше 6 лет опыта",
      "Управляет командой разработчиков",
      "Стандартизирует процессы на проекте",
      "Принимает архитектурные решения",
      "Имеет знания и опыт во всех аспектах разработки программного обеспечения",
      "Участвует в общении с заказчиком",
      "Участвует в стандартизации процессов в компании",
      "Менторит junior / middle / senior разработчиков"
    ]
  }
];

export const CATEGORIES = [
  {
    "id": "GS",
    "name": "010 General Skills"
  },
  {
    "id": "GT",
    "name": "020 General Technical"
  },
  {
    "id": "WEB",
    "name": "030 Web"
  },
  {
    "id": "DB",
    "name": "040 Database"
  },
  {
    "id": "PYTHON",
    "name": "050 Python"
  },
  {
    "id": "ODOO",
    "name": "060 Odoo"
  },
  {
    "id": "FS",
    "name": "070 Fullstack Skills"
  },
  {
    "id": "OOP",
    "name": "080 OOP / Patterns"
  },
  {
    "id": "NM",
    "name": "100 Negative Markers"
  }
];

export const COMPETENCIES = [
  {
    "id": "INTERN-GS-01",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "INTERN",
    "name": "Стремление к самосовершенствованию и постоянному развитию своих профессиональных навыков через обучение и практику",
    "description": "Стремление к самосовершенствованию и постоянному развитию своих профессиональных навыков через обучение и практику. Интерес к изучению новых технологий и методов разработки.",
    "inverted": false
  },
  {
    "id": "INTERN-GT-01",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "INTERN",
    "name": "Способность написать простую программу на основном языке программирования с использованием основных конструкций и функций языка",
    "description": "Включает умение работать с переменными, условиями, циклами и другими базовыми элементами языка, а также создание функций для повторного использования кода.",
    "inverted": false
  },
  {
    "id": "INTERN-GT-02",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "INTERN",
    "name": "Знание основных конструкций и функций основного языка программирования (например, Python), включая типы данных, операторы, функции, работу с файлами и т.д.",
    "description": "Включает понимание и использование различных типов данных (числа, строки, списки, словари), операторов (арифметические, логические, сравнения), функций (встроенные и созданные пользователем), работу с файлами и т.д.",
    "inverted": false
  },
  {
    "id": "INTERN-GT-03",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "INTERN",
    "name": "Понимание основных принципов ООП (наследование, инкапсуляция, полиморфизм) и способность применять их в практике",
    "description": "Включает умение создавать классы и объекты, работать с наследованием и композицией, применять инкапсуляцию для скрытия деталей реализации, использовать полиморфизм для создания гибкого кода.",
    "inverted": false
  },
  {
    "id": "INTERN-GT-04",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "INTERN",
    "name": "Понимание основных принципов работы web-технологий (HTTP, server-client architecture, sessions, DNS и т.д.) и способность применять их в практике",
    "description": "Включает понимание протокола HTTP и принципов работы клиент-серверной архитектуры, способность создавать и использовать сессии, понимание работы DNS и других сетевых протоколов.\"",
    "inverted": false
  },
  {
    "id": "INTERN-GT-05",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "INTERN",
    "name": "Базовое представление об операционных системах, включая виды памяти, процессы/потоки, кодировки и т.д.",
    "description": "Включает понимание основных концепций операционных систем, таких как виды памяти (оперативная, постоянная, виртуальная), процессы и потоки, работу с файловой системой, использование различных кодировок и т.д.",
    "inverted": false
  },
  {
    "id": "INTERN-GT-06",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "INTERN",
    "name": "Базовое представление об архитектуре приложений (MVC, web, authentication и т.д.) и способность применять эти знания в практике",
    "description": "Включает понимание основных архитектурных паттернов, таких как MVC, REST и другие, а также способность применять эти паттерны при разработке приложений. Также включает базовое понимание работы аутентификации и авторизации в веб-приложениях, использование различных API и т.д.",
    "inverted": false
  },
  {
    "id": "INTERN-WEB-01",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "INTERN",
    "name": "Знание основных принципов работы веб-приложений, включая понимание того, что веб-приложения запускаются с помощью специальных программ веб-серверов",
    "description": "Включает знание различных веб-серверов, таких как Apache, Nginx или других, и их роли в разработке веб-приложений.",
    "inverted": false
  },
  {
    "id": "INTERN-WEB-02",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "INTERN",
    "name": "Понимает из каких базовых компонентов состоит UI отображение веб-приложений: HTML, CSS, JS",
    "description": "Включает понимание того, что HTML используется для создания структуры и содержания веб-страницы, CSS используется для оформления и стилизации элементов веб-страницы, а JavaScript используется для создания интерактивных элементов и обработки событий на странице. Включает понимание работы с базовыми HTML-тегами, такими как заголовки, параграфы, списки и таблицы, а также знание основных CSS-свойства, такие как цвета, шрифты, размеры и размещение элементов на странице. Также для этой компетенции важно понимание основ работы с JavaScript, такие как работа с переменными, условиями, циклами, функциями и объектами.",
    "inverted": false
  },
  {
    "id": "INTERN-DB-01",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "INTERN",
    "name": "Знание основ реляционных баз данных, включая понимание структуры таблиц, ключей и связей между ними",
    "description": "Включает знание основных команд языка SQL, таких как select, insert, create, drop, delete, join, groupby, having, where и т.д. Эти навыки позволяют Intern работать с реляционными базами данных и выполнять различные запросы на извлечение, добавление, изменение и удаление данных из таблиц.",
    "inverted": false
  },
  {
    "id": "INTERN-DB-02",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "INTERN",
    "name": "Умение работать с различными типами данных в базах данных, такими как числа, строки, даты, бинарные данные и т.д.",
    "description": "Включает понимание форматов данных и их хранения в базах данных, а также способы работы с ними.",
    "inverted": false
  },
  {
    "id": "INTERN-PYTHON-01",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "INTERN",
    "name": "Знание основных конструкций и функций языка Python, включая понимание переменных, операторов, условий, циклов и функций.",
    "description": "Включает знание типов данных в Python, таких как числа, строки, списки, словари и другие.",
    "inverted": false
  },
  {
    "id": "INTERN-PYTHON-02",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "INTERN",
    "name": "Умение работать с модулями и пакетами в Python",
    "description": "Включает понимание того, как создавать, устанавливать и использовать различные модули и библиотеки. Это включает знание пакетного менеджера pip и умение устанавливать различные библиотеки, такие как NumPy, Pandas, Django и другие",
    "inverted": false
  },
  {
    "id": "INTERN-PYTHON-03",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "INTERN",
    "name": "Понимание принципов ООП в Python, включая понимание инкапсуляции, наследования, полиморфизма и других концепций",
    "description": "Включает умение создавать классы и объекты, работать с наследованием и композицией, использовать инкапсуляцию и полиморфизм для создания гибкого и расширяемого кода.\"",
    "inverted": false
  },
  {
    "id": "INTERN-PYTHON-04",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "INTERN",
    "name": "Умение работать с различными структурами данных в Python, такими как списки, словари, кортежи и другие",
    "description": "Включает знание методов работы с ними, таких как сортировка, фильтрация, преобразование и другие.",
    "inverted": false
  },
  {
    "id": "INTERN-PYTHON-05",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "INTERN",
    "name": "Понимание основных концепций функционального программирования в Python",
    "description": "Включает понимание лямбда-функций, замыканий, декораторов и других концепций. Это включает умение использовать функциональное программирование для написания более эффективного и читабельного кода.",
    "inverted": false
  },
  {
    "id": "INTERN-PYTHON-06",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "INTERN",
    "name": "Знание основ работы с файлами и потоками в Python",
    "description": "Включает понимание работы с текстовыми и бинарными файлами, чтение и запись данных в файлы, работу с контекстными менеджерами и другие.\"",
    "inverted": false
  },
  {
    "id": "INTERN-OOP-01",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "INTERN",
    "name": "Понимание концепций объектно-ориентированного программирования (ООП), включая понимание инкапсуляции, наследования, полиморфизма и других концепций ООП",
    "description": "Имеет базовое понимание концепций ООП, таких как инкапсуляция, наследование и полиморфизм.",
    "inverted": false
  },
  {
    "id": "INTERN-OOP-02",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "INTERN",
    "name": "Умение писать объектно-ориентированный код, включая умение создавать классы и объекты, работать с наследованием и композицией, использовать инкапсуляцию и полиморфизм для создания гибкого и расширяемого кода",
    "description": "Включает умение писать код, используя принципы ООП, такие как создание классов, объектов и методов. Умение применять объектно-ориентированные концепции для решения различных задач и задач.",
    "inverted": false
  },
  {
    "id": "INTERN-NM-01",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "INTERN",
    "name": "Отсутствие инициативы",
    "description": "Постоянно требует руководства и направления даже для простых задач или не берет на себя ответственность за свою работу. Не готов брать на себя новые задачи самостоятельно.",
    "inverted": false
  },
  {
    "id": "INTERN-NM-02",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "INTERN",
    "name": "Неспособность учиться",
    "description": "Не может усвоить новые концепции или технологии даже после нескольких попыток или показывает мало улучшений со временем, это может быть негативным показателем. Не открыт к обучению и не готов приложить усилия, чтобы улучшить свои навыки.",
    "inverted": false
  },
  {
    "id": "INTERN-NM-03",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "INTERN",
    "name": "Плохое управление временем",
    "description": "Постоянно пропускает сроки или тратит слишком много времени на выполнение задач. Не умеет эффективно управлять своим временем и определять приоритеты своей работы.",
    "inverted": false
  },
  {
    "id": "INTERN-NM-04",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "INTERN",
    "name": "Отсутствие внимания к деталям",
    "description": "Постоянно допускает ошибки или упускает важные детали. Не умеет обращать внимание на детали и гордиться своей работой.",
    "inverted": false
  },
  {
    "id": "INTERN-NM-05",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "INTERN",
    "name": "Плохие навыки коммуникации",
    "description": "Трудно эффективно общаться со своей командой или менеджером или не может попросить помощи, когда это необходимо. Не умеет четко общаться и задавать вопросы, когда нужна помощь.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-01",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Способность самостоятельно закрывать мелкие баги и добавлять небольшие фичи в коде",
    "description": "Включает умение быстро находить и исправлять ошибки, а также добавлять новые функциональности, не требующие изменений в архитектуре кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-02",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Способность закрывать задачи под руководством ментора",
    "description": "Включает умение понимать и анализировать задачи, выбирать подходящие методы и инструменты для их решения, а также умение работать с командой и ментором, чтобы достичь поставленных целей.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-03",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Умение находить и выбирать подходящее решение в интернете",
    "description": "Включает умение гуглить и читать документацию, а также выбирать подходящие библиотеки и инструменты для решения задач. Решение не обязательно должно быть оптимальным, но должно соответствовать поставленным требованиям и стандартам качества кода, принятым на проекте.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-04",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Умение общаться в команде, понимание ответственности каждого члена команды и способность находить нужную помощь в случае необходимости",
    "description": "Умение общаться в команде, понимание ответственности каждого члена команды и способность находить нужную помощь в случае необходимости. Это включает умение работать в команде, понимание своей роли и задач, а также умение общаться с другими членами команды.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-05",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Умение правильно формулировать вопросы и просить помощи в команде",
    "description": "Включает умение четко и ясно формулировать свои вопросы и проблемы, а также умение описывать контекст и детали задачи, чтобы помочь коллегам лучше понимать проблему.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-06",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Умение адекватно отстаивать свою точку зрения",
    "description": "Включает умение высказывать свои идеи и мнения, но также умение слушать других членов команды и уважать их мнения. Для этого важно находить баланс между принятием решений в группе и высказыванием своей точки зрения.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-07",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Умение балансировать обучение и просьбы о помощи",
    "description": "Включает умение оценить свои знания и определить, когда нужно обратиться за помощью, а когда можно попробовать разобраться самому. Для этого важно иметь хорошее понимание своих сильных и слабых сторон, а также умение выбирать правильное время для задавания вопросов и просьб о помощи.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-08",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Отсутствие страха задавать 'глупые' вопросы",
    "description": "Для Junior-разработчиков нет глупых вопросов, и важно иметь уверенность в том, что любой вопрос может быть задан и будет принят с пониманием.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-09",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Понимание принципов эстимации задач и умение давать эстимации с небольшой точностью",
    "description": "Включает умение оценивать свое время и учитывать факторы, которые могут повлиять на сроки выполнения задач. Также важно умение объяснять и обосновывать свои эстимации, а также учитывать причины превышения сроков выполнения задач в будущем.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-10",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Самообразование и понимание его важности",
    "description": "Включает самостоятельное изучение технических материалов, таких как курсы, книги, блоги, а также создание домашних проектов. Важно иметь понимание важности непрерывного обучения и постоянного совершенствования своих навыков.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-11",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Умение слушать членов команды и принимать критику своей работы",
    "description": "Для Junior-разработчиков важно иметь возможность получать обратную связь от других членов команды и умение воспринимать эту обратную связь как возможность для роста и улучшения.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-12",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Умение следовать workflow, принятому на конкретном проекте",
    "description": "Это включает понимание и следование процессу работы в команде, а также умение работать с системами контроля версий, такими как Git.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GS-13",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "JUNIOR",
    "name": "Понимание терминологии и умение объяснять свои идеи",
    "description": "Для Junior-разработчиков важно иметь ясное понимание терминологии, используемой в разработке, и умение объяснять свои идеи другим членам команды.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-01",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Уметь правильно именовать единицы кода (переменные, функции, классы и т.п.)",
    "description": "Включает выбор осмысленных и понятных имен переменных, функций, классов и т.д. для улучшения читаемости и поддерживаемости кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-02",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Умение выбирать подходящие design patterns и структуры данных",
    "description": "Важно иметь понимание принципов использования шаблонов проектирования и структур данных, а также умение выбирать подходящие решения для решения конкретных задач.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-03",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Понимает, для чего нужны тесты, и умеет покрывать код юнит-тестами",
    "description": "Включает понимание принципов написания тестов, выбора подходящих инструментов и техник для тестирования, а также умение проводить базовые проверки кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-04",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Умение работать с Git на базовом уровне, включая выполнение основных операций, таких как add, remove, commit, checkout, branch, status, diff, reset, merge и cherry-pick",
    "description": "Включает понимание основных принципов работы с системами контроля версий и умение использовать Git для управления версиями кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-05",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Наличие базовых навыков работы с консолью",
    "description": "Включает умение создавать, переименовывать и удалять папки, подключаться к удаленным серверам, работать с файлами и каталогами в командной строке, читать и понимать документацию и help страницы для команд, искать файлы в командной строке по их названию или содержимому. Также включает умение работать с сервисами и процессами в командной строке, перезапускать сервисы и использовать команды kill для завершения нежелательных процессов. Важно также иметь понимание основных принципов работы с командной строкой и умение использовать ее для выполнения простых задач, таких как создание и удаление файлов, переход между каталогами, выполнение скриптов и т.д.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-06",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Умение применять подход \"keep it simple and comprehensible\" (KISS)",
    "description": "Включает в себя понимание важности простоты и читаемости кода, избегание излишней сложности и использование минимального количества библиотек, необходимых для решения задачи.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-07",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Чтение и понимание чужого кода",
    "description": "Включает в себя способность читать и понимать код, написанный другими разработчиками, в том числе использование документации и комментариев, а также общее понимание архитектуры и структуры проекта.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-08",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Следование workflow на проекте",
    "description": "Включает в себя понимание процессов и правил, установленных на конкретном проекте, включая создание веток, процесс ревью, деплой и т.д., а также способность подстроиться под существующий workflow и работать с командой в соответствии с его правилами.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-09",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Умение использовать базовые функции IDE",
    "description": "Включает в себя настройку окружения, запуск проекта, использование функций редактора кода и просмотр логов. Джун должен знать, как работать с IDE на уровне базовых функций.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-10",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Наличие навыков поиска в кодовой базе проекта",
    "description": "Джун должен уметь находить файлы, функции, классы и переменные в проекте с помощью IDE или командной строки.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-11",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Знание основ алгоритмизации",
    "description": "Джун должен понимать, как работают основные алгоритмы сортировки, поиска и другие базовые алгоритмы. Это поможет джуну оптимизировать свой код и улучшить его производительность.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-12",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Умение обрабатывать простые ошибки",
    "description": "Включает в себя умение распознавать и обрабатывать ошибки на простых примерах, таких как деление на ноль. Разработчик должен понимать, что такие ошибки могут привести к некорректной работе программы и уметь их предотвращать.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-13",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Наличие базовых навыков работы с библиотеками",
    "description": "Включает в себя знание и умение пользоваться базовыми библиотеками для решения ежедневных задач, такими как requests, json, random, os, и т.д. Разработчик должен знать, как эти библиотеки могут помочь в работе и уметь применять их в своих проектах.",
    "inverted": false
  },
  {
    "id": "JUNIOR-GT-14",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "JUNIOR",
    "name": "Умение разворачивать окружение для работы на проекте",
    "description": "Включает в себя умение работать с виртуальными окружениями, создавать файл requirements.txt и устанавливать зависимости с помощью pip или anaconda. Разработчик должен понимать важность правильного разворачивания окружения для эффективной работы на проекте.",
    "inverted": false
  },
  {
    "id": "JUNIOR-WEB-01",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "JUNIOR",
    "name": "Умение работать с веб-серверами включает знакомство с одним или несколькими популярными серверами, такими как Nginx или Apache",
    "description": "Включает знакомство с одним или несколькими популярными серверами, такими как Nginx или Apache. Разработчик должен понимать, как работает веб-сервер, его основные настройки и возможности.",
    "inverted": false
  },
  {
    "id": "JUNIOR-WEB-02",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "JUNIOR",
    "name": "Умение запускать и настраивать локальный сервер для работы",
    "description": "Включает знание базовых принципов настройки сервера, например, установку и настройку в ОС. Разработчик должен уметь настраивать сервер таким образом, чтобы он мог использоваться в связке с другими приложениями, например, с Odoo.",
    "inverted": false
  },
  {
    "id": "JUNIOR-DB-01",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "JUNIOR",
    "name": "Понимание принципов использования индексов",
    "description": "Включает понимание того, как индексы в базах данных улучшают производительность и способность работать с большими объемами данных. Это включает знание различных типов индексов, таких как B-дерево, хэш-индексы и другие, и их применение в базах данных.",
    "inverted": false
  },
  {
    "id": "JUNIOR-DB-02",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "JUNIOR",
    "name": "Понимание основных концепций безопасности в базах данных, таких как аутентификация, авторизация, шифрование и другие",
    "description": "Включает знание методов защиты данных, а также понимание того, что важно обеспечивать безопасность данных в базах данных, чтобы предотвратить несанкционированный доступ и утечку данных.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-01",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Умение придерживаться стандартов кода",
    "description": "Включает знание и понимание стандартов оформления кода, определенных в документе PEP8. Разработчик должен уметь следовать этим стандартам при написании своего кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-02",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Понимание различий между изменяемыми и неизменяемыми объектами в Python (их свойства и поведение)",
    "description": "Включает понимание особенностей изменяемых (mutable) и неизменяемых (immutable) объектов в Python, их различных свойств и поведений. Разработчик должен понимать, когда и как использовать каждый тип объекта.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-03",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Различные способы выполнения кода на Python: exec, eval, ast, code, codeop и т.д.",
    "description": "Включает знание различных способов выполнения кода на Python, таких как exec, eval, ast, code, codeop, и других. Разработчик должен знать особенности каждого метода и уметь выбирать подходящий в зависимости от ситуации.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-04",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Работа с объектами: deepcopy, метод copy, срезы и т.д.",
    "description": "Включает знание различных способов копирования объектов в Python, таких как deepcopy, method copy, slicing, и других. Разработчик должен уметь выбирать подходящий метод для копирования объекта в зависимости от его типа и свойств.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-05",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Протокол итерации, генераторы и ключевое слово yield в Python",
    "description": "Включает понимание протокола итерации (iterator protocol) в Python, генераторов и использования yield. Разработчик должен уметь использовать эти концепции для более эффективной работы с коллекциями данных в своем коде.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-06",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Умение использовать логирование в Python",
    "description": "Включает в себя знание, как работать с модулем logging, использование различных уровней логирования, форматирование вывода логов и настройку логирования в приложении.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-07",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Понимание основ работы с реляционными базами данных в Python",
    "description": "Включает в себя знание SQL и CRUD-операций, умение создавать и удалять таблицы, работать с данными в таблицах и использовать Python ORM (например, SQLAlchemy) для взаимодействия с базами данных.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-08",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Понимание принципа работы и применение декораторов в Python",
    "description": "Включает в себя знание, как создавать декораторы, используя различные подходы, умение применять декораторы к функциям и методам, а также использование декораторов для решения повседневных задач.",
    "inverted": false
  },
  {
    "id": "JUNIOR-PYTHON-09",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "JUNIOR",
    "name": "Умение использовать регулярные выражения в Python для поиска и обработки текста",
    "description": "Включает в себя знание основных синтаксических конструкций регулярных выражений, умение использовать модуль re для работы с регулярными выражениями и решения типичных задач, связанных с обработкой текста.",
    "inverted": false
  },
  {
    "id": "JUNIOR-ODOO-01",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "JUNIOR",
    "name": "Написание простого модуля в Odoo",
    "description": "Разработчик умеет написать простой модуль в Odoo, используя базовые компоненты, такие как модели данных (models), представления (views), права доступа (access rights), меню (menus), отчеты (reports), мастера (wizards), расписания (crons).",
    "inverted": false
  },
  {
    "id": "JUNIOR-ODOO-02",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "JUNIOR",
    "name": "Работа с Odoo ORM",
    "description": "Разработчик знаком с ORM (Object-Relational Mapping) в Odoo, умеет пользоваться базовыми функциями для работы с базой данных, такими как создание и чтение записей, обновление и удаление данных и т.д.",
    "inverted": false
  },
  {
    "id": "JUNIOR-ODOO-03",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "JUNIOR",
    "name": "Понимает особенности всех типов моделей (AbstractModel, Model, TransientModel)",
    "description": "Разработчик понимает особенности всех типов моделей в Odoo: AbstractModel, Model, TransientModel. Он знаком со способами использования каждого из них и может выбрать подходящий тип модели в зависимости от задачи.",
    "inverted": false
  },
  {
    "id": "JUNIOR-ODOO-04",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "JUNIOR",
    "name": "Знакомство со структурой пользовательского интерфейса в Odoo модулях (JS, CSS, XML) и способность к его изменению.",
    "description": "Разработчик знаком со структурой UI компонентов в модулях Odoo (JS, CSS, XML) и может работать с ними, создавая или изменяя пользовательские интерфейсы.",
    "inverted": false
  },
  {
    "id": "JUNIOR-ODOO-05",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "JUNIOR",
    "name": "Умение писать XPath выражения для поиска и изменения элементов в XML файлах",
    "description": "Разработчик умеет писать XPath выражения, которые используются, например, при наследовании вьюхи. Он понимает основные принципы работы XPath и может использовать его для выборки данных из XML-файлов.",
    "inverted": false
  },
  {
    "id": "JUNIOR-ODOO-06",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "JUNIOR",
    "name": "Написание Unit тестов для проверки функциональности модулей в Odoo",
    "description": "Разработчик умеет писать Unit-тесты для своих модулей в Odoo, используя базовые фреймворки, такие как unittest или pytest. Он понимает важность тестирования своего кода и умеет создавать тесты, которые покрывают базовый функционал своих модулей.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-01",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Верстка HTML",
    "description": "Включает умение структурировать HTML согласно стандарту W3C и правильно использовать теги и атрибуты для создания веб-страниц. Разработчик может создавать простые и понятные страницы без использования сложных CSS фреймворков.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-02",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Основы CSS",
    "description": "Включает знание основных концепций CSS, правильный выбор селекторов и применение базовых стилей для создания эффектных и удобочитаемых веб-страниц. Разработчик умеет применять стили, чтобы сделать страницы более понятными и легкими для восприятия.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-03",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Основы JavaScript и работа с DOM",
    "description": "Включает знание типов данных и структур в JavaScript и умение взаимодействовать с DOM в JavaScript (добавлять, удалять, находить элементы, изменять атрибуты и стили). Разработчик умеет использовать JavaScript для динамической модификации веб-страниц и улучшения пользовательского интерфейса.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-04",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Работа с консолью разработчика в браузере",
    "description": "Включает знание и умение использовать консоль разработчика в браузере для отладки JavaScript и CSS. Разработчик умеет использовать различные инструменты, доступные в консоли разработчика, для быстрой и эффективной отладки кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-05",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Работа с ORM",
    "description": "Включает умение использовать ORM (например, sqlalchemy или django-orm) для работы с базами данных. Разработчик понимает, как ORM упрощает работу с базами данных и может эффективно использовать его для реализации функциональности приложения.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-06",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Работа с фреймворком",
    "description": "Включает опыт работы с одним из популярных фреймворков (например, Flask, Django, FastAPI) для создания полноценных веб-приложений. Разработчик знаком со структурой фреймворка и умеет эффективно использовать его для создания веб-приложений.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-07",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Парсинг Excel / XML / JSON / CSV",
    "description": "Включает умение работать с форматами данных Excel, XML, JSON и CSV, а также умение парсить их с помощью различных библиотек и инструментов. Разработчик может обрабатывать данные в различных форматах и использовать их для решения задач веб-приложения.",
    "inverted": false
  },
  {
    "id": "JUNIOR-FS-08",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "JUNIOR",
    "name": "Работа с AJAX запросами и RESTful API",
    "description": "Включает понимание протокола HTTP, умение отправлять и получать данные через AJAX запросы, работу с форматом данных JSON, использование библиотек для упрощения работы с AJAX запросами и понимание принципов RESTful API.",
    "inverted": false
  },
  {
    "id": "JUNIOR-OOP-01",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "JUNIOR",
    "name": "Уверенное знание основ объектно-ориентированного программирования",
    "description": "Включает хорошее понимание концепций ООП, таких как инкапсуляция, наследование и полиморфизм, и умение применять эти концепции в своем коде.",
    "inverted": false
  },
  {
    "id": "JUNIOR-OOP-02",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "JUNIOR",
    "name": "Понимание проектирования классов",
    "description": "Включает понимание, как проектировать классы таким образом, чтобы повысить их переиспользуемость и модульность.",
    "inverted": false
  },
  {
    "id": "JUNIOR-OOP-03",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "JUNIOR",
    "name": "Умение использовать наследование и полиморфизм",
    "description": "Включает умение использовать наследование и полиморфизм для создания иерархий классов и написания более эффективного и поддерживаемого кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-OOP-04",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "JUNIOR",
    "name": "Знание юнит-тестирования в ООП",
    "description": "Включает понимание важности юнит-тестирования в ООП и умение писать базовые юнит-тесты для своего кода.",
    "inverted": false
  },
  {
    "id": "JUNIOR-NM-01",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "JUNIOR",
    "name": "Низкий уровень самоорганизации и ответственности",
    "description": "Частые просрочки сроков, отсутствие планирования своей работы, неумение приоритизировать задачи и неисполнение обещаний. Может приводить к задержкам в разработке и несоответствию сроков.",
    "inverted": false
  },
  {
    "id": "JUNIOR-NM-02",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "JUNIOR",
    "name": "Неспособность разрабатывать тесты для своего кода",
    "description": "Недостаточное покрытие кода тестами, отсутствие понимания важности тестирования и невыполнение требований к тестированию в проекте. Приводит к низкому качеству и ненадежности приложений.",
    "inverted": false
  },
  {
    "id": "JUNIOR-NM-03",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "JUNIOR",
    "name": "Отсутствие понимания основных концепций объектно-ориентированного программирования",
    "description": "Неумение создавать правильную архитектуру приложения, использование плохих практик, например, дублирование кода, нарушение принципов SOLID. Приводит к созданию неэффективного и неструктурированного кода",
    "inverted": false
  },
  {
    "id": "JUNIOR-NM-04",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "JUNIOR",
    "name": "Неспособность работать в команде и эффективно общаться с другими членами команды",
    "description": "Конфликты с коллегами, неспособность объяснить свои идеи и понять идеи других, неумение принимать критику и работать в общих интересах команды. Может приводить к задержкам в разработке и неправильной реализации требований заказчика.",
    "inverted": false
  },
  {
    "id": "JUNIOR-NM-05",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "JUNIOR",
    "name": "Нежелание учиться на своих ошибках",
    "description": "Повторение ошибок, неумение анализировать свои ошибки и искать способы их исправления, неумение принимать ответственность за свои действия.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-01",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Грамотное использование кода из интернета",
    "description": "Включает в себя умение отбирать и применять чужой код, документацию и другие ресурсы в своей работе, а также проверку и анализ того, что было найдено в Интернете.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-02",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Участие в жизненном цикле разработки программного обеспечения",
    "description": "Включает в себя участие в развертывании приложений, проведение код-ревью и участие в процессе принятия решений в команде.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-03",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Способность решать сложные задачи",
    "description": "Включает в себя понимание бизнес-описания и технических деталей, необходимых для реализации сложных задач, а также способность определить оптимальный путь решения задачи.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-04",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Понимание бизнес-процессов заказчика",
    "description": "Включает наличие базовых знаний о бизнес-процессах заказчика и понимание, как его работа может влиять на эти процессы. Умение использовать эту информацию при решении задач.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-05",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Эстимирование задач",
    "description": "Умение эстимировать задачи, используя опыт предыдущих проектов и знание технологий. Для задач, которые занимают до 1 дня, сотрудник способен точно оценить время на их выполнение. Для задач, которые занимают более 3 дней, способен оценивать время с точностью до ± 15%.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-06",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Анализ задачи перед ее выполнением",
    "description": "Включает умение проанализировать задачу и не начинать ее выполнение, пока не получено достаточно информации. Умение задавать правильные вопросы, чтобы понять, какие данные и информация нужны для решения задачи.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-07",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Ответственность за свои задачи",
    "description": "Включает умение нести ответственность за свои задачи и принятые решения, признавать ошибки, а также избегать принципов \"сделал-забыл\" и \"как мне сказали, так я и сделал\".",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-08",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Оценка рисков",
    "description": "Включает умение дать грубую оценку рисков для новой задачи и предвидеть возможные проблемы.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-09",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Технический язык",
    "description": "Включает владение терминологией и умение объяснять свои решения техническим грамотным языком.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-10",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "GDPR и безопасность данных",
    "description": "Включает знание того, что такое GDPR и умение правильно работать с пользовательскими данными, включая безопасность и шифрование.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-11",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Гибкость и умение менять подход",
    "description": "Включает умение вовремя остановиться, обосновать и поменять подход, если текущий подход неэффективен или неподходящий.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GS-12",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "MIDDLE",
    "name": "Инициатива и помощь коллегам",
    "description": "Включает проявление инициативы, предложение улучшений на своем проекте, деление полученных знаний с коллегами и помощь им.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-01",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Выбор подходящих структур данных",
    "description": "Разработчик умеет оценить задачу и выбрать подходящие структуры данных, такие как списки, кортежи, словари и т.п., для оптимальной реализации алгоритма.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-02",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Поддерживаемый код",
    "description": "Разработчик понимает важность поддержки кода и знает о существовании лучших практик (best practices), таких как нейминг переменных, комментирование кода, разделение на функции и т.п., и умеет их применять в своей работе.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-03",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Использование новых фреймворков",
    "description": "Разработчик может начать использовать новый фреймворк, используя его документацию, и быстро начать создавать приложения.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-04",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Выбор фреймворка",
    "description": "Разработчик может выбрать фреймворк для решения конкретной задачи, и обосновать свой выбор, опираясь на его особенности, возможности, и преимущества.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-05",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Дебаггинг и чтение кода фреймворков",
    "description": "Разработчик умеет дебажить свой и чужой код, используя инструменты для поиска и исправления ошибок, а также читать код фреймворков, чтобы понять, как они работают и как можно использовать их функциональность в своей работе.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-06",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Покрытие кода тестами",
    "description": "Включает в себя умение выбрать наиболее критические части кода для покрытия тестами, а также умение выбрать подходящий фреймворк для написания тестов и эффективно их использовать.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-07",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Continuous Integration и Continuous Deployment",
    "description": "Понимает основы процесса CI/CD и умеет настраивать пайплайны для автоматической сборки и тестирования проектов. Может внести изменения в пайплайн, чтобы улучшить процесс.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-08",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Администрирование серверов",
    "description": "Умеет развернуть проект на новом сервере или локально, настраивать окружение для работы проекта и решать базовые проблемы с конфигурацией сервера.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-09",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Регулярные выражения",
    "description": "Умеет писать сложные регулярные выражения для поиска и обработки текста.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-10",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с кодировками",
    "description": "Понимает, как работать с разными кодировками, включая Unicode и UTF-8. Умеет обрабатывать и преобразовывать данные, чтобы избежать ошибок кодирования.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-11",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с датой и временем",
    "description": "Включает в себя понимание таймзон и форматов datetime, умение корректно использовать функции для работы с датой и временем в языке программирования.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-12",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа в командной строке",
    "description": "Включает в себя знание основных команд для работы с файловой системой (например, cd, ls, cp, mv, rm, mkdir), умение управлять процессами (например, ps, kill, bg, fg), умение работать с архивами (например, tar, zip), работать с пакетными менеджерами (например, apt, yum, pip), настройку сетевых интерфейсов и многое другое. Среди основных командных оболочек, которыми должен владеть middle developer, можно выделить bash, zsh, fish.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-13",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Знакомство с API",
    "description": "Включает в себя понимание различных типов API, таких как REST и RPC, а также умение работать с ними в рамках конкретных проектов и с использованием соответствующих инструментов и библиотек.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-14",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Настройка IDE",
    "description": "Включает в себя умение настроить IDE для эффективной работы с проектом, такие как работа с докером, линтерами, гитом и т.д.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-15",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с git",
    "description": "Включает в себя свободное использование git в ежедневной работе, в том числе cherry-pick, rebase, blame и другие команды. Также включает в себя понимание основных концепций git, таких как workspace, index и repository, и умение решать конфликты.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-16",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Знание других языков программирования",
    "description": "Включает в себя умение писать несложный код на других языках, таких как Javascript, в рамках конкретных проектов и при специализации на других языках программирования.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-17",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Чтение кода на других языках",
    "description": "Способность читать и понимать код на других высокоуровневых языках, включая как процедурные, так и объектно-ориентированные языки. Разработчик может проанализировать код и понять его структуру, а также понимать основные конструкции и принципы работы с объектами и функциями.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-18",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Решение без очевидных багов",
    "description": "Разработчик способен принимать решения, которые не содержат очевидных багов. Он может проанализировать задачу и предложить несколько решений, выбрав наиболее оптимальный вариант с точки зрения эффективности и качества кода.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-19",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Асинхронное/синхронное программирование",
    "description": "Разработчик понимает принципы асинхронного и синхронного программирования, а также может использовать соответствующие методы и библиотеки для написания эффективного и быстрого кода.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-20",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с кодовой базой",
    "description": "Разработчик умеет работать с кодом на уровне конкретного класса или метода, понимая и используя его структуру, свойства и методы.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-21",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Принципы безопасности в сети/на сервере/в коде",
    "description": "Разработчик понимает основные принципы безопасности в сети, на сервере и в коде, включая хранение паролей и другой секретной информации, понимание, когда нужно шифровать данные, знание алгоритмов шифрования, использование системы доступа к файлам/папкам в Linux, работа с пользователями в Linux.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-22",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с Docker",
    "description": "Разработчик умеет работать с Docker на базовом уровне, включая разворачивание проекта, подключение IDE, открытие портов и другие основные действия.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-23",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с портами",
    "description": "Разработчик умеет работать с портами, включая открытие, пробрасывание, проксирование и блокировку портов, а также работу с настройками фаервола.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-24",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Использование monkeypatch",
    "description": "Разработчик умеет использовать monkeypatch для изменения поведения кода в юнит-тестах, например, для имитации работы некоторых методов или объектов, с которыми работает код.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-25",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Оптимизация кода",
    "description": "Включает умение находить и исправлять узкие места в производительности приложения, оптимизировать код и запросы к базам данных.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-26",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с многопоточностью",
    "description": "Включае понимание принципов работы многопоточных приложений, умение создавать и использовать потоки, синхронизировать доступ к общим ресурсам.",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-27",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с микросервисами",
    "description": "Включает понимание принципов и технологий микросервисной архитектуры, умение создавать и разворачивать микросервисы",
    "inverted": false
  },
  {
    "id": "MIDDLE-GT-28",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "MIDDLE",
    "name": "Работа с алгоритмами и структурами данных",
    "description": "Понимание основных алгоритмов и структур данных, таких как сортировка, поиск, стек, очередь, бинарное дерево, алгоритм Дейкстры и т.д. Умение выбирать и применять наиболее подходящий алгоритм или структуру данных для решения конкретной задачи.",
    "inverted": false
  },
  {
    "id": "MIDDLE-WEB-01",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "MIDDLE",
    "name": "Web серверы",
    "description": "Включает умение подбирать и настраивать веб-сервер под нужды заказчика, включая такие функции, как проксирование, SSL сертификат, сжатие данных, ограничение доступа, перенаправления, базовую аутентификацию и субдомены.",
    "inverted": false
  },
  {
    "id": "MIDDLE-WEB-02",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "MIDDLE",
    "name": "Понимание принципов работы браузеров и дебаггинг",
    "description": "Включает понимание принципов работы браузеров, таких как интерпретация HTML, CSS, JavaScript, а также работа с DOM и render tree. Также сотрудник должен уметь дебажить и решать проблемы, возникающие при работе в разных браузерах и на разных устройствах. Важно умение использовать инструменты для отладки, такие как Chrome DevTools, Firefox Developer Edition и т.п. для изучения и исправления проблем с отображением, производительностью, сетевыми запросами и т.п.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-01",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Умение работать с SQL на Intermediate уровне",
    "description": "Включает знание ACID, фильтрации, агрегации, сортировки, основных функций, работы с бэкапами и других основных операций. Может правильно спроектировать базу данных небольшого приложения, учитывая нормальные формы в БД.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-02",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Знание основ транзакций и управления транзакционными данными",
    "description": "Включает понимание того, что транзакции являются независимыми операциями с базой данных и должны быть управляемыми, а также знание команд языка SQL, таких как commit, rollback, savepoint и другие.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-03",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Опыт использования Database View, в том числе materialized",
    "description": "Включает умение создавать представления базы данных для удобного и быстрого доступа к информации, а также использование materialized views для повышения производительности запросов.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-04",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Знакомство с функциями и процедурами в PostgreSQL (или других СУБД)",
    "description": "Включает использование PROCEDURE и FUNCTION для управления данными в БД. Включает умение создавать свои собственные функции и процедуры, а также использовать их в запросах для оптимизации работы с данными.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-05",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Умение использовать операторы для условий, преобразований типов, агрегаций, подзапросов (WITH), а также рекурсии",
    "description": "Включает умение использовать сложные запросы для получения необходимых данных, оптимизации работы с большими объемами данных и использования рекурсии для построения деревьев.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-06",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Знакомство с типами данных",
    "description": "Включает знание типов данных, выходящих за рамки ежедневно используемых, и умение применять их при необходимости. Например, знание специфических типов данных для работы с геоданными, JSON, XML и другими специализированными типами данных.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-07",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Понимание принципов работы индексов в БД и умение выбирать и создавать индексы для оптимизации запросов",
    "description": "Включает в себя знание различных типов индексов, их преимуществ и недостатков, а также понимание того, как выбор правильного индекса может ускорить выполнение запросов.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-08",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Понимание принципов и умение работать с кэшированием данных в приложении (например, с использованием Redis)",
    "description": "Включает в себя знание, что такое кэш и как он может использоваться для ускорения работы приложения, а также умение выбрать и настроить подходящее решение для кэширования данных в конкретном проекте.",
    "inverted": false
  },
  {
    "id": "MIDDLE-DB-09",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "MIDDLE",
    "name": "Инструменты анализа запросов",
    "description": "Включает использование в своей работе инструментов PostgreSQL для анализа своих запросов (ANALYZE) и умение решать проблемы производительности запросов (ORM функционала) на уровне БД.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-01",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Понимание концепции корутин (async/await)",
    "description": "Умение использовать asyncio и aiohttp для асинхронной работы с сетью, базами данных и другими ресурсами. Знание основных принципов и паттернов проектирования асинхронных приложений.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-02",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Знание принципов работы функций в Python",
    "description": "Включает:\n- порядок вычисления значений по умолчанию аргументов\n- использование функции partial\n- применение декораторов в Python\n- использование функции wraps для декораторов\n- использование декораторов для классов\n\nУмение писать эффективные, понятные и легко поддерживаемые функции с помощью различных техник.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-03",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Понимание областей видимости (scopes)",
    "description": "Включает понимание правила LEGB (Local / Enclosing / Global / Built-in scope). Умение использовать операторы global и nonlocal для работы с переменными в различных областях видимости. Понимание работы замыканий (closures) и взаимодействия областей видимости и вложенных функций.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-04",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Методы getattr(), setattr(), __getattr__(), __setattr__(), __delattr__(), __getattribute__()",
    "description": "Понимание встроенных функций getattr() и setattr() для получения и изменения значений атрибутов объектов. Понимание магических методов для определения поведения при получении, установке или удалении атрибутов объектов. Понимание магического метода __getattribute__() для определения поведения при получении атрибутов объектов.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-05",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Name mangling",
    "description": "Понимание принципа Name mangling и его использование для создания приватных атрибутов в Python.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-06",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Декоратор @property(getter, setter, deleter)",
    "description": "Понимание декоратора @property и его атрибутов getter, setter и deleter для определения геттеров, сеттеров и дилетеров для атрибутов объектов.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-07",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Магические методы классов",
    "description": "Понимание магических методов init, repr, str, cmp, new, del, hash, nonzero, unicode, class operators и их использование для создания объектов и определения их поведения в Python. Понимание магических методов для сравнения Rich comparison methods (eq, ne, lt, gt, le, ge) и их использование для сравнения объектов в Python. Понимание магического метода call() и его использование для определения поведения вызова объекта как функции в Python.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-08",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Multiple inheritance",
    "description": "Понимание принципа Multiple inheritance и его использование для создания классов с несколькими родителями в Python.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-09",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Classic algorithm",
    "description": "Понимание классического алгоритма разрешения конфликтов при множественном наследовании в Python (C3 linearization) и его использование для определения порядка вызова методов в классах с множественным наследованием.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-10",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Mixins",
    "description": "Понимание паттерна Mixins и его использование для добавления функциональности к классам в Python.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-11",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Метаклассы",
    "description": "Понимание понятия метакласса и его использование для определения поведения создания классов в Python.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-12",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Функции type(), isinstance(), issubclass()",
    "description": "Понимание функций type(), isinstance() и issubclass() для работы с объектами и классами в Python.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-13",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Slots",
    "description": "Понимание магического атрибута slots для оптимизации использования памяти",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-14",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Diamond problem",
    "description": "Знание проблемы бриллианта (diamond problem) при множественном наследовании в языке Python и способы ее решения. Понимание порядка разрешения методов (Method Resolution Order, MRO).",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-15",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Понимание концепции контекстных менеджеров в Python и умение писать свои простые контекстные менеджеры.",
    "description": "Включает в себя знание, как работают контекстные менеджеры, какие задачи они решают и как их использовать в своих проектах, а также умение написать свои контекстные менеджеры для решения повседневных задач.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-16",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Умеет использовать популярные библиотеки",
    "description": "Знание и умение использовать в проекте популярные библиотеки Python для решения различных задач. Включает в себя работу с логированием, коллекциями, обработку и анализ данных, парсинг XML/HTML, работу с базами данных и другие библиотеки. Например, logging, collections, pandas, itertools, lxml, sqlalchemy, etc.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-17",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Умеет эффективно использовать обработку ошибок (в нужных местах, обработка отдельных ошибок и т.п.",
    "description": "Умение правильно обрабатывать и выбрасывать исключения в коде, а также умение использовать конструкции try/except для обработки ошибок в нужных местах. Включает в себя работу с исключениями различного типа и умение выбирать наиболее подходящий способ обработки ошибок для конкретной задачи.",
    "inverted": false
  },
  {
    "id": "MIDDLE-PYTHON-18",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "MIDDLE",
    "name": "Работа с асинхронностью",
    "description": "Включает знание и понимание принципов работы асинхронных приложений, умение использовать асинхронные библиотеки и фреймворки, такие как asyncio или Twisted.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-01",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Умение работать с контроллерами в Odoo",
    "description": "Включает умение создавать и использовать контроллеры в Odoo для реализации функционала как на клиентской, так и на серверной стороне. Также включает знание того, как передавать параметры и использовать различные типы контроллеров в зависимости от поставленных задач.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-02",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Опыт интеграции с внешними сервисами/приложениями",
    "description": "Включает наличие опыта интеграции Odoo с внешними системами, используя API и/или контроллеры. Включает умение настроить взаимодействие с внешними сервисами, например, с платежными системы, веб-приложениями или почтовыми сервисами.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-03",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Знание исходного кода Odoo и возможность вносить изменения в поведение системы",
    "description": "Включает понимание структуры исходного кода Odoo, возможность вносить изменения в поведение системы, умение находить и изменять нужные участки кода для реализации новой функциональности или исправления ошибок.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-04",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Опыт работы с Odoo JavaScript Framework (+ OWL)",
    "description": "Включает знание основных принципов работы Odoo Javascript Framework (и/или OWL) и умение использовать его для разработки пользовательских интерфейсов в Odoo. Умение создавать новые виджеты или использовать стандартные виджеты при необходимости.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-05",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Умение использовать возможности Odoo фреймворка",
    "description": "Включает знание структуры и основных функциональных возможностей Odoo фреймворка. Умение использовать ORM и модели для работы с базой данных. Важно для того, чтобы не разрабатывать свое решение, когда есть функционал, позволяющий сделать это стандартными средствами фреймворка.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-06",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Умение создавать новые XML (JS) виджеты для полей",
    "description": "Включает умение создавать новые XML (JS) виджеты для полей и умение использовать стандартные виджеты в своих модулях. Также включает умение изменить поведение и внешний вид виджетов для удобства пользователей.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-07",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Анализ и изменение front-части модулей",
    "description": "Включает умение анализировать front-часть существующих модулей в Odoo, корректировать поведение и менять незначительные элементы пользовательского интерфейса. Также включает знание основных принципов работы с CSS, HTML и JavaScript для изменения внешнего вида и поведения интерфейса в рамках Odoo, умение использовать инструменты для отладки и тестирования пользовательского интерфейса.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-08",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Эффективное использование legacy-кода и репозиториев OCA для разработки front-компонентов в Odoo",
    "description": "Включает умение эффективно использовать legacy-код и репозитории OCA в качестве примеров для разработки своих собственных front-компонентов в Odoo.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-09",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Понимание протокола обмена данными между клиентом и сервером в Odoo",
    "description": "Включает понимание принципов работы протокола обмена данными между клиентом (браузером) и сервером в Odoo (JSON, JSONRPC), умение использовать соответствующие инструменты и библиотеки для работы с выбранным протоком обмена данными.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-10",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Понимание связи между элементами и событиями в браузере и методами ORM моделей и контроллеров Odoo",
    "description": "Включает понимание связи между элементами и событиями в браузере и методами ORM моделей и контроллеров в Odoo, умение обрабатывать события (например, нажатие на кнопку, движение мыши и т.п.) или создавать новые событие для элементов страницы",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-11",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Знакомство с UI библиотеками, используемыми Odoo в стандартных модулях",
    "description": "Включает умение работать с интерфейсом пользовательской части Odoo и знакомство с используемыми библиотеками. Также включает знание, какие компоненты и элементы интерфейса могут быть использованы для создания собственных модулей.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-12",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Создание PDF отчетов",
    "description": "Включает умение создавать и настраивать отчеты в формате PDF в Odoo без использования таблиц. Также включает знание, как работает процесс генерации отчетов и возможность объяснения его новым разработчикам на простых примерах.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-13",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Workers / Cron workers",
    "description": "Включает умение настроить несколько Odoo instances, работающих с одной базой (для обеспечения надежности и отказоустойчивости системы). Знакомство с принципами работы workers и cron workers в Odoo и умение настраивать их для оптимизации производительности.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-14",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Environment",
    "description": "Включает понимание и умение использовать Environment объекты в рамках своих модулей для решение поставленных задач",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-15",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Транзакции в Odoo",
    "description": "Включает знание как работать с транзакциями в Оду (в каких случаях можно использовать создание транзакций вручную, использование commit/rollback/savepoint). Наличие понимания как преобразуются ORM методы в запросы базы данных (и умение эффективно выбирать какие из них использовать).",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-16",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Модуль queue_job",
    "description": "Включает понимание принципа работы модуля queue_job в Odoo и умение использовать его для обработки задач в фоновом режиме. Знакомство с механизмом планирования задач и умение настраивать cron jobs для выполнения отложенных задач. Понимание принципа работы worker threads.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-17",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Стандартные модули",
    "description": "Включает знакомство с модулями Sales, Purchase, Inventory, Invoicing и умение использовать их функциональность в соответствии с требованиями проекта. Знание, как настраивать вышеуказанные модули для удовлетворения конкретных бизнес-потребностей.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-18",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Написание тестов",
    "description": "Включает умение создавать тесты, при необходимости заменяя API вызовы и стандартные функции на заглушки (моки), чтобы тестировать код в изоляции. Умение использовать библиотеки для создания моков и возможность оценки, какие части кода требуют заглушек для тестирования.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-19",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Использование XMLRPC",
    "description": "Умение использовать XMLRPC для обмена данными между различными системами и написания скриптов для быстрого изменения данных.",
    "inverted": false
  },
  {
    "id": "MIDDLE-ODOO-20",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "MIDDLE",
    "name": "Использование Scheduled Action",
    "description": "Умение настраивать Scheduled Action для автоматического запуска скриптов в определенное время, а также для чистки данных, исправления данных, быстрого изменения данных (\"на коленке\").",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-01",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Анализ состояния веб-страниц",
    "description": "Включает умение анализировать и определять состояние веб-страниц, находить ошибки в структуре документа и скриптах. Знание, как использовать инструменты браузера для отладки, настройки и оптимизации веб-страницы.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-02",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Эффективное использование инструментов JavaScript",
    "description": "Знание основ JavaScript и умение использовать различные инструменты, такие как отладчик, инспектор объектов, библиотеки и фреймворки для разработки функциональных и качественных приложений.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-03",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Эффективное использование CSS фреймворков",
    "description": "Знание основных принципов CSS и умение использовать CSS фреймворки, такие как Bootstrap, для быстрой и качественной разработки веб-страниц. Умение настраивать и дополнять стили фреймворков по своим потребностям.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-04",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Эффективное использование JS библиотек",
    "description": "Знание основных библиотек JavaScript, таких как jQuery, и умение использовать их для разработки динамических веб-страниц. Умение настраивать и дополнять библиотеки по своим потребностям.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-05",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Понимание компилируемых стилей",
    "description": "Знание принципов работы препроцессоров CSS, таких как SCSS и LESS, и умение использовать их для написания более гибкого и удобного кода CSS. Понимание, как работают функции, миксины и переменные в препроцессорах CSS.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-06",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Понимание Deferred и async/await",
    "description": "Знание принципов работы асинхронного программирования в JavaScript и умение использовать конструкции Deffered (jQuery) / async/await для управления асинхронными операциями в веб-приложениях. Понимание разницы между синхронным и асинхронным кодом и умение обрабатывать ошибки в асинхронных операциях.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-07",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Понимание функционирования объекта XMLHttpRequest",
    "description": "Знание, как использовать объект XMLHttpRequest для отправки запросов на сервер без перезагрузки страницы. Знание основных методов и свойств объекта, таких как open(), send(), onreadystatechange, status и т.д.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-08",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Эффективное использование консоли разработчика браузера для дебага",
    "description": "Включает умение использовать консоль разработчика браузера для отладки кода на стороне клиента. Знание, как использовать различные команды консоли для проверки переменных, выполнения скриптов, отслеживания событий и т.д.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-09",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Умение находить UI / JS библиотеки, подходящие для решения задач клиента",
    "description": "Умение выбирать и использовать подходящие UI / JS библиотеки для реализации функциональности на стороне клиента. Знание, какие библиотеки наиболее популярны и широко используются на текущий момент.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-10",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Работа с мессенджерами и очередями",
    "description": "Включает знание и понимание принципов работы мессенджеров и очередей, таких как RabbitMQ или Apache Kafka, умение создавать и использовать очереди для обработки асинхронных задач, понимание, как организовать обмен сообщениями между компонентами приложения с использованием мессенджеров и очередей, понимание основных концепций обмена сообщениями (producer, consumer, message broker), умение обрабатывать ошибки при работе с очередями и мессенджерами.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-11",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Оптимизация загрузки веб-страниц",
    "description": "Включает знание и умение оптимизировать загрузку веб-страниц. Разработчик должен уметь минимизировать размер CSS и JavaScript файлов, оптимизировать размер изображений, использовать кэширование ресурсов и другие методы оптимизации. Кроме того, важно умение работать с инструментами анализа производительности сайтов, такими как Google PageSpeed Insights и Lighthouse.",
    "inverted": false
  },
  {
    "id": "MIDDLE-FS-12",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "MIDDLE",
    "name": "Архитектура клиент-серверных приложений",
    "description": "Включает глубокое понимание и умение применять принципы архитектуры клиент-серверных приложений (например, RESTful API, микросервисная архитектура). Умение выбирать подходящий подход и архитектуру для решения конкретной задачи, учитывая требования к масштабируемости, надежности, производительности и т.д.",
    "inverted": false
  },
  {
    "id": "MIDDLE-OOP-01",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "MIDDLE",
    "name": "Знает паттерны проектирования",
    "description": "Включает базовое понимание распространенных паттернов проектирования, таких как Singleton, Factory и Observer, и знает, как применять их в своем коде.",
    "inverted": false
  },
  {
    "id": "MIDDLE-OOP-02",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "MIDDLE",
    "name": "Углубленное понимание концепций объектно-ориентированного программирования",
    "description": "Включает глубокое понимание концепций ООП, таких как инкапсуляция, наследование и полиморфизм, и уметь объяснять эти концепции другим.",
    "inverted": false
  },
  {
    "id": "MIDDLE-OOP-03",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "MIDDLE",
    "name": "Возможность использования продвинутых возможностей ООП",
    "description": "Включает умение использовать продвинутые возможности ООП, такие как абстрактные классы, интерфейсы и обобщения, для написания эффективного и поддерживаемого кода.",
    "inverted": false
  },
  {
    "id": "MIDDLE-OOP-04",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "MIDDLE",
    "name": "Опыт работы с ООП-фреймворками и библиотеками",
    "description": "Включает наличие опыта использования ООП-фреймворков и библиотек, таких как Flase, Django, SQLAlchemy, etc. и умение интегрировать их в свой код.",
    "inverted": false
  },
  {
    "id": "MIDDLE-OOP-05",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "MIDDLE",
    "name": "Знание принципов проектирования и архитектурных паттернов ООП",
    "description": "Включает знакомство с продвинутыми принципами проектирования ООП, такими как SOLID и GRASP, и умение применять их в своем коде. Понимание архитектурных паттернов, такие как MVC, и умение проектировать и реализовывать масштабируемые и поддерживаемые архитектуры.",
    "inverted": false
  },
  {
    "id": "MIDDLE-NM-01",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "MIDDLE",
    "name": "Низкий уровень самоорганизации и ответственности",
    "description": "Сотрудник с низким уровнем самоорганизации и ответственности имеет трудности в планировании своей работы и соблюдении сроков. Он может забывать о задачах, не уметь расставлять приоритеты и не следовать за договоренностями. Такой сотрудник может создавать проблемы в команде и ухудшать ее эффективность. Cидит и ждет, если старший товарищ / менеджер сразу не ответил (или забыл).",
    "inverted": false
  },
  {
    "id": "MIDDLE-NM-02",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "MIDDLE",
    "name": "Нежелание принимать решения и брать на себя ответственность за свою работу",
    "description": "Сотрудник, который не хочет принимать решения и не берет на себя ответственность за свою работу, может приводить к задержкам и проблемам в проекте. Он может не уметь оценивать риски и не готов решать проблемы, что может отрицательно сказываться на работе команды. Работает по принципам: \"сделал-забыл\" / \"как мне сказали, так я и сделал\".",
    "inverted": false
  },
  {
    "id": "MIDDLE-NM-03",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "MIDDLE",
    "name": "Низкий уровень самокритичности и готовности к критике своей работы",
    "description": "Сотрудник с низким уровнем самокритичности и готовности к критике своей работы может не учитывать замечания и советы коллег и не стремиться улучшать свои навыки. Он может не видеть ошибок в своей работе, что может привести к ошибкам и снижению качества продукта. Препятствует улучшению качества кода и процессов разработки.",
    "inverted": false
  },
  {
    "id": "MIDDLE-NM-04",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "MIDDLE",
    "name": "Отсутствие понимания основных принципов и методов разработки, таких как SOLID, TDD, DDD",
    "description": "Приводит к созданию неструктурированного и неэффективного кода, который будет трудно поддерживать и расширять в будущем. Недостаточно хорошо продуманный и спроектированный код может приводить к ошибкам и неэффективной работе приложения.",
    "inverted": false
  },
  {
    "id": "MIDDLE-NM-05",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "MIDDLE",
    "name": "Отсутствие стремления к обучению и изучению новых технологий, фреймворков и методов разработки",
    "description": "Приводит к устареванию навыков и ухудшению качества разработки.",
    "inverted": false
  },
  {
    "id": "MIDDLE-NM-06",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "MIDDLE",
    "name": "Нежелание работать в команде и участвовать в общих делах проекта",
    "description": "Приводит к конфликтам и снижению эффективности работы.",
    "inverted": false
  },
  {
    "id": "MIDDLE-NM-07",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "MIDDLE",
    "name": "Недостаточное внимание к документации и комментированию кода",
    "description": "Затрудняет поддержку и развитие проекта в будущем.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-01",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Способность к самостоятельной работе",
    "description": "Включает способность выполнять задачи без детального описания, умение самостоятельно оценивать сложность задачи и выбирать наиболее оптимальный способ ее решения.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-02",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Обучающие навыки",
    "description": "Включает обучение и консультирование коллег по техническим вопросам, проведение лекций и воркшопов на темы своей компетенции. Умение объяснять сложные концепции и методы разработки в понятной форме для других разработчиков.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-03",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Решение проблем",
    "description": "Включает умение решать сложные технические проблемы, используя аналитические и проблемно-ориентированные подходы. Способность анализировать сложные ситуации и принимать решения в условиях неопределенности и высокого риска.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-04",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Умение задавать правильные вопросы на правильном уровне абстракции",
    "description": "Умение задавать вопросы, которые помогают уточнить требования и понять цель задачи. Способность задавать вопросы на разных уровнях абстракции - от конкретных деталей до высокоуровневых целей - для получения полной картины проекта и принятия обоснованных технических решений.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-05",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Несение ответственности за принятые технические решения",
    "description": "Способность нести ответственность за принятые технические решения, а также принимать на себя риски и решать проблемы в ходе выполнения проекта. Понимание важности технической ответственности и ее влияния на результаты проекта.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-06",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Умение эстимировать задачи и оценивать эстимации других разработчиков в команде",
    "description": "Способность проводить оценку сложности задач и объема работы для определения ресурсов, необходимых для выполнения проекта. Умение оценивать эстимации других разработчиков, а также давать рекомендации по оптимизации и улучшению процесса оценки. Понимание важности точности оценок для успешного выполнения проекта.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-07",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Определение точки достаточного решения",
    "description": "Способность определять момент, когда решение достаточно хорошо, вместо того, чтобы тратить время на бесконечные улучшения или оставлять проблемы нерешенными. Умение учитывать факторы, такие как сроки, бюджет и другие ограничения проекта, чтобы принимать обоснованные решения.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-08",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Участие в митингах с заказчиком",
    "description": "Умение общаться с заказчиком на английском языке и понимать его требования и потребности. Способность использовать свои знания технологии для поддержки аналитика и участия в митингах с заказчиком, чтобы помочь обеспечить более эффективное взаимодействие между заказчиком и командой разработки.",
    "inverted": false
  },
  {
    "id": "SENIOR-GS-09",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "SENIOR",
    "name": "Трансляция бизнес-требований в технические задачи",
    "description": "Способность переводить бизнес-требования в понятные и конкретные технические задачи для разработчиков. Умение работать с бизнес-аналитиками и заказчиками, чтобы понять их потребности и требования, и превратить их в технические задачи для команды разработки.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-01",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Умение программировать на разных языках",
    "description": "Включает навыки программирования на нескольких языках и способность писать код любой сложности. Понимание принципов работы языков и умение применять их для решения различных задач. Готовность использовать наиболее подходящий инструмент для достижения поставленных целей.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-02",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Гибкость в выборе фреймворков",
    "description": "Включает отсутствие зависимости от конкретных фреймворков и умение проводить анализ, выбирая наиболее подходящий инструмент для решения задачи. Понимание принципов работы различных фреймворков и способность применять их для создания эффективных решений.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-03",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Backend / Frontend разработка",
    "description": "Обладание навыками как backend, так и frontend разработки и возможность выполнения задач с обеих сторон. Знание принципов работы серверной и клиентской частей и умение использовать соответствующие технологии для реализации поставленных задач.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-04",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Прототипирование",
    "description": "Обладание навыками прототипирования и умение самостоятельно написать MVP продукт в предсказуемые и целесообразные сроки и бюджет. Понимание принципов работы различных инструментов для прототипирования и умение выбирать наиболее подходящий для решения поставленных задач. Знание принципов работы UX/UI и умение создавать прототипы, учитывающие потребности пользователей.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-05",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Умение организовать эффективный CI/CD",
    "description": "Включает умение проектировать и настраивать систему непрерывной интеграции и развертывания (CI/CD) на новых и существующих проектах. Знание современных инструментов для автоматизации сборки, тестирования, деплоя и мониторинга. Умение настраивать пайплайны CI/CD для различных сред разработки и продакшена.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-06",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Архитектурное мышление",
    "description": "Умение работать с кодом на уровне проекта, понимание архитектуры системы и умение проследить зависимости между классами. Способность визуализировать структуру проекта и видеть \"большую картину\" вместе с деталями. Обладание навыками проектирования расширяемых, модульных и легко поддерживаемых систем.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-07",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Имплементация интерфейсов",
    "description": "Умение / практика имплементации интерфейсов (для методов) перед тем как имплементировать логику. Понимание, что интерфейсы - это ключевой инструмент для проектирования расширяемых и модульных систем. Умение определить общие методы и реализовать их через интерфейсы. Способность проектировать и использовать интерфейсы для улучшения кода, его тестируемости и переиспользования.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-08",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Способность практиковать TDD",
    "description": "Умение разрабатывать программное обеспечение с использованием методологии Test-Driven Development (TDD), при которой тесты пишутся до написания кода. Разработчик понимает принципы TDD и может успешно применять их в проектах.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-09",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Практический опыт создания микросервисов",
    "description": "Наличие практического опыта работы с микросервисами, используя Docker. Умение работать с контейнерами, понимание того, как они взаимодействуют между собой и какие проблемы могут возникнуть при их использовании.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-10",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Способность запланировать или выполнить рефакторинг",
    "description": "Эта компетенция предполагает, что разработчик может проводить рефакторинг кода любой сложности, как своего, так и чужого. Разработчик должен уметь видеть архитектурные проблемы и косяки в коде и знать, как их исправить, не нарушив функциональность системы.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-11",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Понимание и практика построения сложных отчетов",
    "description": "Эта компетенция предполагает, что разработчик имеет опыт создания сложных отчетов с использованием библиотек (например, pandas) или готовых инструментов (например, PowerBI). Разработчик должен понимать, что такое Data Warehouse и OLAP cube, а также принципы сбора данных из систем для их анализа.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-12",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Мониторинг",
    "description": "Эта компетенция предполагает, что разработчик понимает, что и зачем нужно мониторить в рамках проекта. Разработчик должен уметь определять метрики и параметры, которые нужно мониторить для обеспечения стабильной и надежной работы приложения.",
    "inverted": false
  },
  {
    "id": "SENIOR-GT-13",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "SENIOR",
    "name": "Управление безопасностью данных / приложений",
    "description": "Способность создавать архитектуру приложения с учетом требований безопасности и защиты от атак. Специалист способен определить возможные угрозы безопасности, связанные с разработкой приложения, и разработать стратегию защиты от них. Он также умеет применять передовые методы и технологии, такие как шифрование данных, многофакторная аутентификация и проверка на проникновение, для обеспечения максимальной безопасности приложения.",
    "inverted": false
  },
  {
    "id": "SENIOR-WEB-01",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "SENIOR",
    "name": "Умение работать с дополнительными модулями веб-серверов",
    "description": "Включает понимание принципов работы с geo balancing и load balancing, и способность при необходимости настроить кластер из нескольких серверов с балансировкой нагрузки. Знание принципов работы и конфигурации таких модулей, как HAProxy, Varnish и другие.",
    "inverted": false
  },
  {
    "id": "SENIOR-WEB-02",
    "categoryId": "WEB",
    "category": "030 Web",
    "level": "SENIOR",
    "name": "Понимание принципов работы веб-серверов",
    "description": "Включает глубокое понимание того, как работают веб-серверы и что происходит внутри них при обработке запросов, передаче данных и других операциях. Он может эффективно настраивать и оптимизировать веб-серверы, чтобы обеспечить максимальную производительность и надежность веб-приложений. Кроме того, он понимает, как работают прокси-серверы, балансировщики нагрузки и другие инструменты для улучшения производительности и масштабируемости веб-серверов.",
    "inverted": false
  },
  {
    "id": "SENIOR-DB-01",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "SENIOR",
    "name": "Анализ БД и оптимизация производительности",
    "description": "Умение проанализировать объем данных в БД и принять меры для очистки устаревших данных и улучшения производительности: способность провести аудит базы данных, идентифицировать устаревшие данные, неиспользуемые индексы и прочие узкие места, а также предложить и реализовать меры для оптимизации производительности БД.",
    "inverted": false
  },
  {
    "id": "SENIOR-DB-02",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "SENIOR",
    "name": "Partitioning и кластеризация в PostgreSQL",
    "description": "Включает умение работать с PostgreSQL Partitions, понимание принципов кластеризации. Умение применять эти технологии для оптимизации работы с БД. Может выбрать подходящую стратегию partitioning, настроить ее и провести миграцию данных в новую схему. Умение настроить кластеризацию в PostgreSQL для обеспечения высокой доступности и отказоустойчивости.",
    "inverted": false
  },
  {
    "id": "SENIOR-DB-03",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "SENIOR",
    "name": "Умение настраивать и оптимизировать работу СУБД на уровне конфигурационных файлов",
    "description": "Включает в себя знание параметров конфигурации и их значения, а также понимание, как эти настройки могут повлиять на производительность базы данных.",
    "inverted": false
  },
  {
    "id": "SENIOR-DB-04",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "SENIOR",
    "name": "Работа с триггерами",
    "description": "Включает опыт работы с триггерами в PostgreSQL и других базах данных. Понимание, как триггеры могут использоваться для автоматического выполнения заданных действий при изменении данных в БД, таких как вставка, обновление и удаление записей. Сотрудник способен разработать триггеры для автоматизации рутинных задач и повышения производительности работы с данными.",
    "inverted": false
  },
  {
    "id": "SENIOR-DB-05",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "SENIOR",
    "name": "Умение работать с репликацией данных и понимание принципов работы механизмов репликации",
    "description": "Включает в себя знание различных типов репликации данных, их преимуществ и недостатков, а также умение выбрать подходящий механизм репликации для конкретного проекта.",
    "inverted": false
  },
  {
    "id": "SENIOR-DB-06",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "SENIOR",
    "name": "Знакомство с принципами NoSQL баз данных (например, MongoDB) и умение выбирать подходящую БД для конкретного проекта",
    "description": "Включает в себя знание различных типов NoSQL баз данных, их преимуществ и недостатков, а также умение выбрать подходящую БД для конкретного проекта, исходя из его требований и характеристик.",
    "inverted": false
  },
  {
    "id": "SENIOR-DB-07",
    "categoryId": "DB",
    "category": "040 Database",
    "level": "SENIOR",
    "name": "Знакомство с принципами работы распределенных баз данных",
    "description": "Включает умение выбирать подходящую архитектуру для конкретного проекта (например, шардинг данных). Включает в себя знание принципов работы распределенных баз данных, их преимуществ и недостатков, а также умение выбрать подходящую архитектуру для конкретного проекта, исходя из его требований и характеристик.",
    "inverted": false
  },
  {
    "id": "SENIOR-PYTHON-01",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "SENIOR",
    "name": "Интроспекция функций",
    "description": "Понимание, как можно получить информацию о функциях во время выполнения программы, например, их аргументы, имена атрибутов и документацию. Умение использовать интроспекцию для различных задач, например, для отладки или для создания более гибких и абстрактных функций.",
    "inverted": false
  },
  {
    "id": "SENIOR-PYTHON-02",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "SENIOR",
    "name": "Функциональное программирование",
    "description": "Понимание концепций функционального программирования, таких как функции первого класса, замыкания, генераторы, рекурсия и т.д. Умение применять эти концепции для написания более гибких и поддерживаемых функций, а также для улучшения производительности и сокращения кода.",
    "inverted": false
  },
  {
    "id": "SENIOR-PYTHON-03",
    "categoryId": "PYTHON",
    "category": "050 Python",
    "level": "SENIOR",
    "name": "Фабрики декораторов",
    "description": "Понимание концепции декораторов и умение создавать функции-декораторы, которые принимают аргументы и могут изменять поведение функций, к которым они применяются.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-01",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Умение эффективно использовать возможности Odoo фреймворка",
    "description": "Включает хорошее понимание архитектуры и функциональных возможностей Odoo фреймворка, включая модули для управления бизнес-процессами, финансами, производством и складом. Разработчик должен уметь выбирать наиболее подходящее решение из нескольких вариантов и учитывать последствия его принятых решений.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-02",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Уверенное знание ОСА модулей",
    "description": "Включает наличие опыта работы с различными ОСА модулями в Odoo, включая модули для управления продажами, закупками, управления проектами и т.д. Разработчик должен периодически просматривать все репозитории ОСА, чтобы быть в курсе новых модулей и возможностей, которые могут быть использованы в текущих и будущих проектах.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-03",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Чтение и анализ JS кода OWL фреймворка",
    "description": "Включает наличие опыта работы с веб-модулями более ранних версий Odoo и умение читать и анализировать JavaScript код, написанных с использованием фреймворка OWL. Разработчик должен иметь понимание основных концепций и компонентов фреймворка, таких как виджеты, контроллеры и действия, и уметь применять их для создания собственных модулей и настройки пользовательского интерфейса.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-04",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Отличия JS стандартов, использованных в разных версиях Odoo",
    "description": "Включает хорошее понимание различий между стандартами JavaScript, используемыми в разных версиях Odoo. Разработчик должен знать особенности каждой версии и уметь адаптироваться к ним, чтобы успешно решать задачи клиентов и улучшать работу существующих приложений.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-05",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Умение расширять любые UI компоненты стандартных Odoo модулей",
    "description": "Включает наличие опыта работы с UI компонентами стандартных модулей Odoo (forms, trees, etc.), и умение их расширять. Разработчик должен иметь хорошее понимание CSS и HTML, а также опыт работы с фреймворком QWeb для настройки пользовательского интерфейса. Он также должен быть способен расширять функциональность компонентов с помощью JavaScript и других технологий, таких как AJAX и REST API.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-06",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Интегрирует сторонние UI библиотеки для решения задач клиента",
    "description": "Включает наличие опыта работы с различными сторонними UI библиотеками, такими как Bootstrap, plot.ly, vue.js и т.д. Разработчик должен знать, как интегрировать эти библиотеки в Odoo, чтобы решить задачи клиентов и улучшить пользовательский интерфейс.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-07",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Настройка Odoo с помощью параметров в odoo.conf",
    "description": "Включает понимание и умение правильно подобрать настройки для всех основных параметров в файле odoo.conf",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-08",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Настройка Routes в Inventory модуле",
    "description": "<TODO>",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-09",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Настройка основных бухгалтерских отчетов",
    "description": "Включает умение объяснить и настроить основные бухгалтерские отчеты, используя стандатный функционал Odoo Enterprise",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-10",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Умеет настроить финансовые отчеты с помощью модуля mis_builder",
    "description": "Включает наличие опыта работы с модулем mis_builder в Odoo и умение настраивать финансовые отчеты для отображения соответствующей информации о финансовом состоянии компании. Разработчик должен знать, как использовать инструменты mis_builder для создания отчетов и уметь настраивать параметры отчетов для удовлетворения потребностей бизнеса.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-11",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Модуль Accounting в Odoo",
    "description": "Включает хорошее понимание модуля Accounting в Odoo и умение консультировать по его использованию. Разработчик должен также уметь вносить технические правки в поведение модуля без помощи других технических специалистов или функциональных консультантов.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-12",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Использование backend-инструментов для разработки кастомных интерфейсов в Odoo",
    "description": "Включает хорошее понимание различных методов разработки интерфейса и понимание, когда целесообразно использовать backend-инструменты Odoo для создания пользовательского интерфейса вместо традиционной front-разработки.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-13",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Построение эффективных репортов",
    "description": "Включает хорошее знание SQL и понимать, как создавать materialized view для ускорения работы с отчетами в Odoo. Разработчик должен знать, когда следует использовать отчеты вместо расширения стандартных моделей Odoo, чтобы обеспечить максимальную эффективность и производительность системы.",
    "inverted": false
  },
  {
    "id": "SENIOR-ODOO-14",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "SENIOR",
    "name": "Решение проблем типа lock / deadlock",
    "description": "Включает хорошее понимание проблем с блокировками и дедлоками в базах данных и знание, как их выявлять и устранять в Odoo. Разработчик должен иметь опыт работы с SQL и знать, как использовать инструменты мониторинга производительности, такие как pg_stat_statements, для выявления проблем в производительности. Он также должен иметь хорошее понимание многопоточности в Python и знать, как правильно использовать блокировки и синхронизацию потоков для предотвращения проблем с блокировками и дедлоками.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-01",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "Современные JS фреймворки",
    "description": "Эта компетенция подразумевает знание различных современных фреймворков на языке JavaScript, таких как React, Angular, Vue.js и других. Сотрудник должен понимать основные принципы работы каждого фреймворка и знать их преимущества и недостатки. Он также должен быть способен выбрать подходящий фреймворк для конкретного проекта.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-02",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "Тенденции развития JS",
    "description": "Эта компетенция подразумевает, что сотрудник следит за последними тенденциями и новостями в развитии JavaScript стандартов и фреймворков. Он должен быть в курсе последних обновлений и изменений в языке и знать, какие фреймворки и библиотеки становятся наиболее популярными.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-03",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "Стандарты ES5 / ES6 / ES7",
    "description": "Эта компетенция подразумевает знание и понимание стандартов языка JavaScript, таких как ES5, ES6 и ES7. Сотрудник должен знать различия между версиями стандартов и понимать, какие новые возможности и функциональности были добавлены в каждой версии.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-04",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "TypeScript",
    "description": "Эта компетенция подразумевает знание языка TypeScript, который является надстройкой над JavaScript и добавляет статическую типизацию, классы и интерфейсы. Сотрудник должен знать основные концепции и синтаксис TypeScript, а также уметь использовать его в разработке.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-05",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "Понимание принципов работы WebSocket и умение использовать их для создания реального времени приложений",
    "description": "Включает знание, как работает WebSocket API для установления двустороннего соединения между браузером и сервером. Понимание, как использовать WebSocket API для отправки и получения сообщений в реальном времени и как обрабатывать разрывы соединения.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-06",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "Понимание принципов SPA (Single Page Application) и опыт работы с фреймворками, такими как React, Vue.js или Angular",
    "description": "Включает знание принципов работы SPA, умение работать с одним из указанных фреймворков, знание основных концепций, таких как компоненты, роутинг, жизненный цикл компонентов и другие.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-07",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "Знание основ работы с базами данных NoSQL (например, MongoDB, Firebase)",
    "description": "Включает понимание принципов работы баз данных NoSQL, умение работать с одной из указанных баз данных, знание основных типов данных и структур данных, работу с запросами к базе данных.",
    "inverted": false
  },
  {
    "id": "SENIOR-FS-08",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "SENIOR",
    "name": "Умение оптимизировать производительность веб-приложения",
    "description": "Например, минимизация размера файлов, оптимизация загрузки ресурсов, уменьшение количества запросов на сервер. Включает знание принципов оптимизации производительности веб-приложений, умение использовать различные инструменты для минимизации размера файлов, оптимизации загрузки ресурсов, уменьшения количества запросов на сервер и других оптимизаций.",
    "inverted": false
  },
  {
    "id": "SENIOR-OOP-01",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "SENIOR",
    "name": "Продвинутое использование возможностей ООП",
    "description": "Умеет использовать продвинутые возможности ООП, такие как множественное наследование, генераторы, метаклассы и другие.",
    "inverted": false
  },
  {
    "id": "SENIOR-OOP-02",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "SENIOR",
    "name": "Разработка масштабируемых, расширяемых и поддерживаемых архитектур на основе ООП",
    "description": "Cпособен создавать высоко масштабируемые, расширяемые и поддерживаемые архитектуры на основе принципов ООП.",
    "inverted": false
  },
  {
    "id": "SENIOR-NM-01",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "SENIOR",
    "name": "Отсутствие желания и способности передавать знания и опыт младшим разработчикам в команде",
    "description": "Неспособность разбираться в сложных концепциях программирования и передавать свои знания более младшим коллегам в команде. Это может привести к тому, что проект не будет реализован оптимально, так как команда не будет понимать принципы и методы, применяемые при разработке.",
    "inverted": false
  },
  {
    "id": "SENIOR-NM-02",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "SENIOR",
    "name": "Неспособность создавать масштабируемые, расширяемые и поддерживаемые архитектуры",
    "description": "Отсутствие навыков проектирования масштабируемых, расширяемых и поддерживаемых архитектур, что может привести к трудностям в будущем при поддержке и развитии проекта.",
    "inverted": false
  },
  {
    "id": "SENIOR-NM-03",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "SENIOR",
    "name": "Способность объяснять сложные концепции программирования простым языком для менее опытных коллег",
    "description": "Включает в себя умение прояснять сложные концепции программирования для менее опытных коллег, использовать понятный и доступный язык, не впадать в технический жаргон и помогать другим членам команды лучше понимать сложные аспекты разработки.",
    "inverted": false
  },
  {
    "id": "SENIOR-NM-04",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "SENIOR",
    "name": "Не может эффективно управлять своим временем и приоритетами в работе",
    "description": "Отсутствие способности эффективно управлять своим временем и приоритетами в работе, что приводит к невыполнению задач в срок и низкой производительности. Такой разработчик может быть неспособен принимать решения о том, что делать в первую очередь, и не будет иметь четкого понимания, какое задание важнее другого.",
    "inverted": false
  },
  {
    "id": "SENIOR-NM-05",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "SENIOR",
    "name": "Отсутствие желания и способности передавать знания и опыт младшим разработчикам в команде",
    "description": "Неспособность работать в команде и передавать знания и опыт другим членам команды. Это может привести к тому, что проект не будет реализован оптимально, так как команда не будет обладать необходимыми навыками и знаниями.",
    "inverted": false
  },
  {
    "id": "SENIOR-NM-06",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "SENIOR",
    "name": "Не обладает хорошими коммуникативными навыками, не может эффективно общаться с заказчиками и менеджментом проектов",
    "description": "Отсутствие навыков эффективного общения с заказчиками и менеджментом проектов, что может привести к недопониманию требований и невыполнению поставленных задач в срок.",
    "inverted": false
  },
  {
    "id": "SENIOR-NM-07",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "SENIOR",
    "name": "Не способен эффективно работать в команде, не учитывает мнение других участников и не сотрудничает с ними",
    "description": "Отсутствие способности работать в команде и учитывать мнение других участников, что может привести к конфликтам в команде, непониманию и неэффективной работе. Такой разработчик не готов работать в команде, не уважает мнение других участников и не проявляет гибкость в общении. Это может привести к задержкам в проектах и плохому качеству кода, так как отсутствует сотрудничество и обмен идеями с другими членами команды. Также это может сказаться на атмосфере в команде и привести к плохим отношениям между ее участниками.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-01",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Full-stack разработка ПО",
    "description": "Понимание и опыт работы в различных аспектах разработки программного обеспечения, от сбора требований и управления до архитектурного дизайна, разработки кода и других артефактов, интеграции и тестирования / контроля качества. Способность работать с различными языками программирования, фреймворками и инструментами, а также согласовывать работу различных компонентов программного обеспечения. Управление жизненным циклом программного обеспечения (SDLC)",
    "inverted": false
  },
  {
    "id": "LEAD-GS-02",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Делегирование задач",
    "description": "Способность определять правильное распределение задач между участниками команды разработчиков на основе их знаний, навыков и производительности. Умение оценивать сложность задач и предлагать соответствующие решения, чтобы достичь поставленных целей.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-03",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Управление командой",
    "description": "Умение организовать и эффективно управлять командой разработчиков, включая распределение задач, координацию работы и мотивацию членов команды. Умение создавать хорошую рабочую атмосферу и решать конфликты внутри команды.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-04",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Управление многими проектами",
    "description": "Способность быстро переключаться между различными задачами на разных проектах и умение контролировать процесс работы в разных проектах. Умение оценивать приоритеты и ресурсы для каждого проекта, чтобы достичь поставленных целей. Также включает в себя умение управлять проектами в разных этапах жизненного цикла, от планирования до завершения.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-05",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Ведение документации",
    "description": "Умение описывать архитектуру проекта, процессы и другие аспекты разработки программного обеспечения с ясной структурой и подробным описанием, что позволяет легко понять проект и его текущее состояние.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-06",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Brainstorming",
    "description": "Умение проводить сеансы \"мозгового штурма\" с членами команды и/или заказчиками для поиска идей, решений проблем, а также оценки возможных рисков и последствий.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-07",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Участие в пресейл митингах",
    "description": "Умение представлять компанию на презентациях и собраниях, включая технические аспекты проекта, с целью продвижения продукта и привлечения новых клиентов.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-08",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Коммуникация с заказчиком",
    "description": "Способность эффективно общаться с заказчиком, выяснять его требования и предлагать наиболее подходящие решения в ходе диалога на большинство вопросов, что способствует более качественной и продуктивной работе.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-09",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Формирование \"гаража проектов\"",
    "description": "Способность собирать идеи для потенциальных фич или продуктов, выбирая наиболее перспективные задачи для команды на основе изученных материалов и потребностей заказчиков на проектах.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-10",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Погружение в задачу и обзор \"сверху\"",
    "description": "Способность быстро переключаться между глубоким погружением в задачу и обзором \"сверху\" с целью эффективного контроля за процессом и достижения поставленных целей.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-11",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Разработка архитектуры и интеграция",
    "description": "Способность разрабатывать архитектуру и реализовывать интеграционные проекты, которые связывают разнородные системы в рамках большого проекта.",
    "inverted": false
  },
  {
    "id": "LEAD-GS-12",
    "categoryId": "GS",
    "category": "010 General Skills",
    "level": "LEAD",
    "name": "Стандартизация процессов",
    "description": "Умение установить стандарты и процессы в рамках проекта и коммуницировать их с другими членами команды, чтобы обеспечить единообразную работу и достижение поставленных целей.",
    "inverted": false
  },
  {
    "id": "LEAD-GT-01",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "LEAD",
    "name": "Принятие архитектурных решений",
    "description": "Разработчик должен быть способен анализировать требования к проекту и принимать решения относительно общей архитектуры системы. Он должен быть знаком с основными архитектурными паттернами и уметь применять их в решении конкретных задач. Принятие архитектурных решений помогает создавать системы, которые легко масштабировать, поддерживать и расширять.",
    "inverted": false
  },
  {
    "id": "LEAD-GT-02",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "LEAD",
    "name": "Управление техническим долгом (постоянный поиск, подсчёт стоимости и постепенное устранение)",
    "description": "Технический долг - это мера того, насколько сложно поддерживать и развивать существующий код. Лид разработчик должен уметь управлять техническим долгом в команде, постоянно контролировать его уровень, анализировать и устранять причины его возникновения.",
    "inverted": false
  },
  {
    "id": "LEAD-GT-03",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "LEAD",
    "name": "Разработка и реализация программных систем",
    "description": "Разработчик должен иметь опыт в проектировании и внедрении программных систем. Он должен уметь анализировать требования к системе, определять ее архитектуру, выбирать необходимые технологии и библиотеки, руководить командой разработчиков при создании системы с нуля.",
    "inverted": false
  },
  {
    "id": "LEAD-GT-04",
    "categoryId": "GT",
    "category": "020 General Technical",
    "level": "LEAD",
    "name": "Проектирование микросервисов и их взаимодействия",
    "description": "Разработчик должен быть способен принимать решения о выделении отдельных функциональных блоков в микросервисы, что улучшает масштабируемость и поддерживаемость системы. Он должен уметь анализировать требования к функционалу, выбирать подходящие технологии и библиотеки, а также продумывать архитектуру взаимодействия микросервисов с основной системой.",
    "inverted": false
  },
  {
    "id": "LEAD-DB-01",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "LEAD",
    "name": "Понимание функциональных и технических возможностей Odoo",
    "description": "Включает владение глубоким пониманием возможностей, особенностей и ограничений Оду в решении бизнес-задач. Умение анализировать требования заказчика и подбирать подходящие модули для реализации функциональности.",
    "inverted": false
  },
  {
    "id": "LEAD-DB-02",
    "categoryId": "ODOO",
    "category": "060 Odoo",
    "level": "LEAD",
    "name": "Опыт деплоя Оду в Kubernetes",
    "description": "Включает наличие опыта развертывания Оду как сервиса в микросервисной архитектуре с помощью Kubernetes. Знание основных принципов работы с Kubernetes, в том числе развертывание контейнеров и управление ресурсами. Способность настраивать и масштабировать Оду в Kubernetes в соответствии с требованиями проекта.",
    "inverted": false
  },
  {
    "id": "LEAD-FS-01",
    "categoryId": "FS",
    "category": "070 Fullstack Skills",
    "level": "LEAD",
    "name": "Знание принципов проектирования пользовательских интерфейсов",
    "description": "Включает понимание, как проектировать интерфейсы, которые будут удобны и интуитивно понятны для пользователей. Разработчик знает, как улучшить пользовательский опыт и сделать интерфейс более доступным. Он способен проанализировать потребности пользователей и создать удобный интерфейс, который поможет им быстро и эффективно выполнять свои задачи. Лид-разработчик также знаком с основными концепциями и методами дизайна пользовательских интерфейсов, такими как wireframing, prototyping и user testing. Он может провести исследования пользовательского опыта и анализировать данные, чтобы сделать улучшения в интерфейсе. Эта компетенция важна для создания продуктов, которые будут полезны и удобны для пользователей.",
    "inverted": false
  },
  {
    "id": "LEAD-OOP-01",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "LEAD",
    "name": "Микросервисная архитектура",
    "description": "Включает наличие глубокого понимания и опыта разработки приложений, основанных на микросервисной архитектуре. Умение правильно разбивать приложение на микросервисы, определять их границы, управлять транзакционной целостностью и выбирать механизмы взаимодействия между микросервисами.",
    "inverted": false
  },
  {
    "id": "LEAD-OOP-02",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "LEAD",
    "name": "Событийно-ориентированная архитектура",
    "description": "Включает понимание схем, которые позволяют строить системы на основе событий, умение проектировать и реализовывать такие системы, а также выбирать и настраивать механизмы работы с сообщениями.",
    "inverted": false
  },
  {
    "id": "LEAD-OOP-03",
    "categoryId": "OOP",
    "category": "080 OOP / Patterns",
    "level": "LEAD",
    "name": "Архитектурный стиль RESTful",
    "description": "Включает опыт проектирования и разработки RESTful API, знание основных принципов, в том числе HATEOAS, и умение правильно применять их в своей работе. Различия REST и SOAP.\nЧто такое Contract First (контракт в первую очередь) и Code First (код в первую очередь)?",
    "inverted": false
  },
  {
    "id": "LEAD-NM-01",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "LEAD",
    "name": "Низкий уровень эмоционального интеллекта и недостаточная способность эффективно управлять командой разработчиков",
    "description": "Недостаточное понимание социальных динамик в команде и затруднение в эффективном общении с членами команды. Неспособность создавать поддерживающую и включающую среду для коллег, а также неспособность решать конфликты и разрешать проблемы в рамках команды. Отсутствие понимания чувств и потребностей членов команды, неспособность наладить эффективное взаимодействие, влияет на качество работы команды и может приводить к недовольству и конфликтам.",
    "inverted": false
  },
  {
    "id": "LEAD-NM-02",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "LEAD",
    "name": "Отсутствие стратегического мышления и способности планировать разработку на долгосрочной основе",
    "description": "Отсутствие способности видеть долгосрочную перспективу в разработке, неумение определять критические точки проекта и планировать их развитие на длительный период. Неспособность увидеть целостную картину проекта, а также неспособность планировать работы на долгосрочной основе может привести к неэффективности проектов и несоответствию результатов ожиданиям.",
    "inverted": false
  },
  {
    "id": "LEAD-NM-03",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "LEAD",
    "name": "Недостаточное понимание бизнес-процессов и потребностей заказчика",
    "description": "Недостаточное понимание бизнес-среды, в которой работает компания, и неспособность находить эффективные решения, учитывающие потребности заказчика. Отсутствие опыта работы с клиентами и знаний о том, как их потребности могут изменяться со временем. Может привести к неправильному планированию проекта, ошибкам в разработке и неудовлетворенности клиентов.",
    "inverted": false
  },
  {
    "id": "LEAD-NM-04",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "LEAD",
    "name": "Отсутствие готовности и способности развивать и поддерживать высокий уровень профессионализма в команде",
    "description": "Неспособность создавать обучающую среду и обеспечивать постоянное профессиональное развитие членов команды. Недостаточное внимание к новым технологиям и трендам в индустрии и неспособность поддерживать высокие стандарты качества и эффективности в работе. Недостаточная готовность и способность развивать профессиональные компетенции членов команды, недостаточное внимание к обучению и развитию профессиональных навыков, может привести к тому, что команда не сможет эффективно выполнять свои задачи и не будет соответствовать требованиям рынка.",
    "inverted": false
  },
  {
    "id": "LEAD-NM-05",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "LEAD",
    "name": "Неспособность эффективно управлять конфликтами внутри команды и взаимодействовать с другими отделами компании",
    "description": "Недостаточное понимание причин конфликтов и неспособность принимать конструктивные решения для их разрешения. Неспособность взаимодействовать с другими отделами компании и учитывать их потребности при решении проблем и задач. Может привести к затруднениям в работе команды и взаимодействии с другими отделами компании, а также к снижению производительности и удовлетворенности команды.",
    "inverted": false
  },
  {
    "id": "LEAD-NM-06",
    "categoryId": "NM",
    "category": "100 Negative Markers",
    "level": "LEAD",
    "name": "Недостаточная готовность и способность действовать в условиях быстро меняющегося бизнес-окружения",
    "description": "Неготовность к изменениям и неумение быстро приспосабливаться к новым условиям, может привести к задержкам в проектах, потере конкурентных преимуществ и проблемам в отношениях с заказчиками. Кроме того, если сотрудник не может адаптироваться к быстро меняющимся технологическим трендам и новым инновациям, то это может привести к устареванию компетенций и неспособности конкурировать на рынке. Поэтому, сотрудник должен постоянно развиваться, изучать новые технологии и следить за изменениями в отрасли, чтобы оставаться конкурентоспособным и готовым к быстрым изменениям в бизнес-окружении. Может привести к задержкам в проектах, снижению качества работы и недовольству заказчиков.",
    "inverted": false
  }
];

export const ANSWERS = [
  {
    id: 'novice',
    name: 'Новичок',
    description: 'Ничего не знаю',
  },
  {
    id: 'beginner',
    name: 'Начинающий',
    description: 'Слышал об этом',
  },
  {
    id: 'intermediate',
    name: 'Средний уровень',
    description: 'Встречал на практике',
  },
  {
    id: 'advanced',
    name: 'Продвинутый',
    description: 'Есть опыт работы',
  },
  {
    id: 'expert',
    name: 'Эксперт',
    description: 'Эксперт в этом',
  }
];

export const NM_ANSWERS = [
  {
    id: 'never',
    name: 'Никогда',
    description: 'Я никогда не проявляю этого навыка или не веду себя таким образом',
  },
  {
    'id': 'rarely',
    name: 'Редко',
    description: 'Я проявляю этот навык или веду себя таким образом только иногда',
  },
  {
    id: 'sometimes',
    name: 'Иногда',
    description: 'Я проявляю этот навык или веду себя таким образом относительно регулярно',
  },
  {
    id: 'often',
    name: 'Часто',
    description: 'Я проявляю этот навык или веду себя таким образом часто',
  },
  {
    id: 'always',
    name: 'Всегда',
    description: 'Я постоянно проявляю этот навык или веду себя таким образом во всех соответствующих ситуациях',
  },
]