import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function NotFound() {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 56px)' }}>
      <div className="text-center">
        <h1 className="fw-bold d-block">404</h1>
        <p className="fs-3">
          {' '}
          <span className="text-danger">Opps!</span>
          {' '}
          Page not found.
        </p>
        <p className="lead">
          The page you’re looking for doesn’t exist.
        </p>
        <Button as={NavLink} to="/" variant="primary">Go Home</Button>
      </div>
    </div>
  );
}

export default NotFound;
