import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Form, Row, Table } from 'react-bootstrap';

// eslint-disable-next-line no-unused-vars
const QUESTIONS_2 = [
  {
    question: 'I love large parties.',
    translation: 'Я люблю большие вечеринки.',
    answers: {
      D: '0.1146',
      I: '0.6706',
      S: '-0.3081',
      C: '-0.7583',
    },
  },
  {
    question: 'I enjoy being part of a loud crowd.',
    translation: 'Мне нравится быть частью шумной толпы.',
    answers: {
      D: '0.142',
      I: '0.6765',
      S: '-0.3591',
      C: '-0.7434',
    },
  },
  {
    question: 'I make lots of noise.',
    translation: 'Я много шуму разгоняю.',
    answers: {
      D: '0.2961',
      I: '0.5525',
      S: '-0.5285',
      C: '-0.668',
    },
  },
  {
    question: 'I prefer to participate fully rather than view life from the sidelines.',
    translation: 'Я предпочитаю полностью участвовать в жизни, а не наблюдать со стороны.',
    answers: {
      D: '0.2924',
      I: '0.298',
      S: '-0.3571',
      C: '-0.5336',
    },
  },
  {
    question: 'I seek adventure.',
    translation: 'Я ищу приключений.',
    answers: {
      D: '0.2522',
      I: '0.3142',
      S: '-0.3289',
      C: '-0.4874',
    },
  },
  {
    question: 'I have a strong need for power.',
    translation: 'У меня сильная потребность во власти.',
    answers: {
      D: '0.6934',
      I: '-0.0433',
      S: '-0.7613',
      C: '-0.412',
    },
  },
  {
    question: 'I laugh aloud.',
    translation: 'Я смеюсь вслух.',
    answers: {
      D: '0.0719',
      I: '0.3955',
      S: '-0.1513',
      C: '-0.3856',
    },
  },
  {
    question: 'I willing to try anything once.',
    translation: 'Я готов попробовать что-нибудь однажды.',
    answers: {
      D: '0.1887',
      I: '0.2516',
      S: '-0.2454',
      C: '-0.3837',
    },
  },
  {
    question: 'I joke around a lot.',
    translation: 'Я часто шучу.',
    answers: {
      D: '-0.013',
      I: '0.4548',
      S: '-0.1339',
      C: '-0.3701',
    },
  },
  {
    question: 'I want strangers to love me.',
    translation: 'Я хочу, чтобы незнакомцы меня любили.',
    answers: {
      D: '-0.2084',
      I: '0.5098',
      S: '0.095',
      C: '-0.3346',
    },
  },
  {
    question: 'I run to get where I am going faster.',
    translation: 'Я бегу, чтобы быстрее добраться до места назначения.',
    answers: {
      D: '0.3113',
      I: '0.1123',
      S: '-0.3901',
      C: '-0.3055',
    },
  },
  {
    question: 'I find humour in everything.',
    translation: 'Я нахожу юмор во всем.',
    answers: {
      D: '-0.0894',
      I: '0.4086',
      S: '-0.0263',
      C: '-0.3044',
    },
  },
  {
    question: 'I am always on the look out for ways to make money.',
    translation: 'Я всегда ищу способы заработать деньги.',
    answers: {
      D: '0.397',
      I: '-0.0116',
      S: '-0.4254',
      C: '-0.2985',
    },
  },
  {
    question: 'I boast about my virtues.',
    translation: 'Я хвастаюсь своими достоинствами.',
    answers: {
      D: '0.2581',
      I: '0.1491',
      S: '-0.3749',
      C: '-0.2984',
    },
  },
  {
    question: 'I demand the recognition I deserve.',
    translation: 'Я требую признания, которое заслуживаю.',
    answers: {
      D: '0.3417',
      I: '0.0824',
      S: '-0.4485',
      C: '-0.2974',
    },
  },
  {
    question: 'I have a good word for everyone.',
    translation: 'У меня есть доброе слово для каждого.',
    answers: {
      D: '-0.262',
      I: '0.3606',
      S: '0.292',
      C: '-0.2933',
    },
  },
  {
    question: 'I speed up to avoid being passed.',
    translation: 'Я ускоряюсь, чтобы избежать обгона.',
    answers: {
      D: '0.2984',
      I: '0.0516',
      S: '-0.3386',
      C: '-0.2458',
    },
  },
  {
    question: 'If lots of people like something, I usually will too.',
    translation: 'Если многим людям что-то нравится, мне обычно тоже.',
    answers: {
      D: '-0.1811',
      I: '0.2739',
      S: '0.2224',
      C: '-0.2238',
    },
  },
  {
    question: 'I believe that others have good intentions.',
    translation: 'Я верю, что у других людей хорошие намерения.',
    answers: {
      D: '-0.2293',
      I: '0.2918',
      S: '0.258',
      C: '-0.1952',
    },
  },
  {
    question: 'Sometimes I have a hard time taking my eyes off of an attractive person.',
    translation: 'Иногда мне трудно отвести взгляд от привлекательного человека.',
    answers: {
      D: '0.0885',
      I: '0.1551',
      S: '-0.1354',
      C: '-0.1931',
    },
  },
  {
    question: 'I put people under pressure.',
    translation: 'Я оказываю давление на людей.',
    answers: {
      D: '0.7358',
      I: '-0.2224',
      S: '-0.8018',
      C: '-0.1825',
    },
  },
  {
    question: 'I try to outdo others.',
    translation: 'Я стараюсь превзойти других.',
    answers: {
      D: '0.4749',
      I: '-0.0763',
      S: '-0.6354',
      C: '-0.0928',
    },
  },
  {
    question: 'I always remember when someone compliments me.',
    translation: 'Я всегда помню комплименты, которые мне делают.',
    answers: {
      D: '-0.1093',
      I: '0.1593',
      S: '0.0827',
      C: '-0.0843',
    },
  },
  {
    question: 'I call people out when they tell fake or exaggerated stories.',
    translation: 'Я указываю на ложь или преувеличение в рассказах других людей.',
    answers: {
      D: '0.3784',
      I: '-0.1437',
      S: '-0.4214',
      C: '-0.0711',
    },
  },
  {
    question: 'I like to do nice things for people even if I get no credit.',
    translation: 'Мне нравится делать хорошие дела для людей, даже если я не получаю заслуженное признание.',
    answers: {
      D: '-0.1855',
      I: '0.1273',
      S: '0.2159',
      C: '-0.0611',
    },
  },
  {
    question: 'I like to call people by their last names.',
    translation: 'Я люблю обращаться к людям по фамилии.',
    answers: {
      D: '0.1547',
      I: '-0.0608',
      S: '-0.1903',
      C: '-0.0489',
    },
  },
  {
    question: "I don't like people who dress messy.",
    translation: 'Мне не нравятся люди, которые одеваются небрежно.',
    answers: {
      D: '0.1807',
      I: '-0.1221',
      S: '-0.1881',
      C: '-0.0081',
    },
  },
  {
    question: 'I think some people are just better than others.',
    translation: 'Я думаю, что некоторые люди просто лучше других.',
    answers: {
      D: '0.3109',
      I: '-0.1668',
      S: '-0.3391',
      C: '0.0091',
    },
  },
  {
    question: 'I lose my patience when I get tired.',
    translation: 'Я теряю терпение, когда устаю.',
    answers: {
      D: '0.1903',
      I: '-0.1086',
      S: '-0.1646',
      C: '0.046',
    },
  },
  {
    question: 'I just want everyone to be equal.',
    translation: 'Я хочу, чтобы все были равными.',
    answers: {
      D: '-0.4421',
      I: '0.1835',
      S: '0.4704',
      C: '0.0569',
    },
  },
  {
    question: 'I value cooperation over competition.',
    translation: 'Я ценю сотрудничество выше конкуренции.',
    answers: {
      D: '-0.4825',
      I: '0.1843',
      S: '0.5441',
      C: '0.0763',
    },
  },
  {
    question: 'My trust gets broken a lot.',
    translation: 'Мое доверие часто нарушается.',
    answers: {
      D: '0.0362',
      I: '-0.0985',
      S: '-0.0583',
      C: '0.109',
    },
  },
  {
    question: "I get jealous of other people's friendships.",
    translation: 'Я ревную чужие дружбы.',
    answers: {
      D: '-0.0779',
      I: '-0.0118',
      S: '0.0933',
      C: '0.1329',
    },
  },
  {
    question: "I hesitate to criticize other people's ideas.",
    translation: 'Я колеблюсь, прежде чем критиковать чужие идеи.',
    answers: {
      D: '-0.5269',
      I: '0.1273',
      S: '0.6263',
      C: '0.1406',
    },
  },
  {
    question: 'I phrase things diplomatically.',
    translation: 'Я выражаю свои мысли дипломатично.',
    answers: {
      D: '-0.2558',
      I: '0.0693',
      S: '0.2194',
      C: '0.1649',
    },
  },
  {
    question: 'I ask why things really happened.',
    translation: 'Я задаюсь вопросом, почему что-то произошло на самом деле.',
    answers: {
      D: '0.0887',
      I: '-0.1059',
      S: '-0.163',
      C: '0.1685',
    },
  },
  {
    question: 'I prefer to make friends with people exactly like me.',
    translation: 'Я предпочитаю заводить друзей среди людей, точно таких же, как я.',
    answers: {
      D: '-0.0217',
      I: '-0.1721',
      S: '0.063',
      C: '0.1753',
    },
  },
  {
    question: 'I would never cheat on my taxes.',
    translation: 'Я бы никогда не сжульничал в налогах.',
    answers: {
      D: '-0.1754',
      I: '-0.1068',
      S: '0.2583',
      C: '0.1953',
    },
  },
  {
    question: "I don't ever litter.",
    translation: 'Я никогда не мусорю.',
    answers: {
      D: '-0.1036',
      I: '-0.1255',
      S: '0.1442',
      C: '0.2213',
    },
  },
  {
    question: 'I hate it when people want to make changes for no reason.',
    translation: 'Меня раздражает, когда люди хотят вносить изменения без веской причины.',
    answers: {
      D: '-0.044',
      I: '-0.2758',
      S: '0.1065',
      C: '0.3231',
    },
  },
  {
    question: 'I hate to seem pushy.',
    translation: 'Я не люблю казаться навязчивым.',
    answers: {
      D: '-0.56',
      I: '0.0782',
      S: '0.5833',
      C: '0.3251',
    },
  },
  {
    question: 'I seldom toot my own horn.',
    translation: 'Я редко хвалюсь своими успехами.',
    answers: {
      D: '-0.2612',
      I: '-0.1909',
      S: '0.3887',
      C: '0.3309',
    },
  },
  {
    question: 'My first reaction to an idea is to see its flaws.',
    translation: 'Моя первая реакция на идею - увидеть ее недостатки.',
    answers: {
      D: '0.1896',
      I: '-0.4506',
      S: '-0.2252',
      C: '0.464',
    },
  },
  {
    question: 'I do not like small talk.',
    translation: 'Мне не нравится мелкая болтовня.',
    answers: {
      D: '0.1722',
      I: '-0.5368',
      S: '-0.1134',
      C: '0.47',
    },
  },
  {
    question: 'I avoid mistakes.',
    translation: 'Я стараюсь избегать ошибок.',
    answers: {
      D: '-0.0641',
      I: '-0.3621',
      S: '0.0732',
      C: '0.4943',
    },
  },
  {
    question: 'I leave parties early.',
    translation: 'Я ухожу с вечеринок пораньше.',
    answers: {
      D: '-0.0149',
      I: '-0.5017',
      S: '0.1921',
      C: '0.4962',
    },
  },
  {
    question: 'I love order and regularity.',
    translation: 'Я люблю порядок и регулярность.',
    answers: {
      D: '-0.1189',
      I: '-0.4278',
      S: '0.1641',
      C: '0.5302',
    },
  },
  {
    question: 'I am always guarded.',
    translation: 'Я всегда настороже.',
    answers: {
      D: '-0.0101',
      I: '-0.563',
      S: '0.1324',
      C: '0.568',
    },
  },
  {
    question: 'I read the fine print.',
    translation: 'Я читаю мелкий шрифт.',
    answers: {
      D: '-0.0746',
      I: '-0.3868',
      S: '-0.0472',
      C: '0.574',
    },
  },
  {
    question: 'I am emotionally reserved.',
    translation: 'Я сдержан в проявлении своих эмоций.',
    answers: {
      D: '-0.1637',
      I: '-0.6445',
      S: '0.3095',
      C: '0.7542',
    },
  },
];

// eslint-disable-next-line no-unused-vars
const QUESTIONS = [
  {
    question: 'I enjoy being part of a loud crowd.',
    translation: 'Мне нравится быть частью шумной толпы.',
    answers: {
      D: '0.142',
      I: '0.6765',
      S: '-0.3591',
      C: '-0.7434',
    },
  },
  {
    question: 'I make lots of noise.',
    translation: 'Я много шуму разгоняю.',
    answers: {
      D: '0.2961',
      I: '0.5525',
      S: '-0.5285',
      C: '-0.668',
    },
  },
  {
    question: 'I have a strong need for power.',
    translation: 'У меня сильная потребность во власти.',
    answers: {
      D: '0.6934',
      I: '-0.0433',
      S: '-0.7613',
      C: '-0.412',
    },
  },
  {
    question: 'I joke around a lot.',
    translation: 'Я часто шучу.',
    answers: {
      D: '-0.013',
      I: '0.4548',
      S: '-0.1339',
      C: '-0.3701',
    },
  },
  {
    question: 'I want strangers to love me.',
    translation: 'Я хочу, чтобы незнакомцы меня любили.',
    answers: {
      D: '-0.2084',
      I: '0.5098',
      S: '0.095',
      C: '-0.3346',
    },
  },
  {
    question: 'I am always on the look out for ways to make money.',
    translation: 'Я всегда ищу способы заработать деньги.',
    answers: {
      D: '0.397',
      I: '-0.0116',
      S: '-0.4254',
      C: '-0.2985',
    },
  },
  {
    question: 'I put people under pressure.',
    translation: 'Я оказываю давление на людей.',
    answers: {
      D: '0.7358',
      I: '-0.2224',
      S: '-0.8018',
      C: '-0.1825',
    },
  },
  {
    question: 'I try to outdo others.',
    translation: 'Я стараюсь превзойти других.',
    answers: {
      D: '0.4749',
      I: '-0.0763',
      S: '-0.6354',
      C: '-0.0928',
    },
  },
  {
    question: 'I just want everyone to be equal.',
    translation: 'Я хочу, чтобы все были равными.',
    answers: {
      D: '-0.4421',
      I: '0.1835',
      S: '0.4704',
      C: '0.0569',
    },
  },
  {
    question: 'I value cooperation over competition.',
    translation: 'Я ценю сотрудничество выше конкуренции.',
    answers: {
      D: '-0.4825',
      I: '0.1843',
      S: '0.5441',
      C: '0.0763',
    },
  },
  {
    question: "I hesitate to criticize other people's ideas.",
    translation: 'Я колеблюсь, прежде чем критиковать чужие идеи.',
    answers: {
      D: '-0.5269',
      I: '0.1273',
      S: '0.6263',
      C: '0.1406',
    },
  },
  {
    question: 'I seldom toot my own horn.',
    translation: 'Я редко хвалюсь своими успехами.',
    answers: {
      D: '-0.2612',
      I: '-0.1909',
      S: '0.3887',
      C: '0.3309',
    },
  },
  {
    question: 'My first reaction to an idea is to see its flaws.',
    translation: 'Моя первая реакция на идею - увидеть ее недостатки.',
    answers: {
      D: '0.1896',
      I: '-0.4506',
      S: '-0.2252',
      C: '0.464',
    },
  },
  {
    question: 'I love order and regularity.',
    translation: 'Я люблю порядок и регулярность.',
    answers: {
      D: '-0.1189',
      I: '-0.4278',
      S: '0.1641',
      C: '0.5302',
    },
  },
  {
    question: 'I read the fine print.',
    translation: 'Я читаю мелкий шрифт.',
    answers: {
      D: '-0.0746',
      I: '-0.3868',
      S: '-0.0472',
      C: '0.574',
    },
  },
  {
    question: 'I am emotionally reserved.',
    translation: 'Я сдержан в проявлении своих эмоций.',
    answers: {
      D: '-0.1637',
      I: '-0.6445',
      S: '0.3095',
      C: '0.7542',
    },
  },
];

const calculateDISCScores = (userAnswers) => {
  // Define the Likert scale
  const likertScale = {
    Несогласен: 0,
    'Слегка несогласен': 1,
    'Нейтрально, без мнения': 2,
    'Слегка согласен': 3,
    Согласен: 4,
  };

  // Initialize the DISC scores
  const scores = {
    D: 0,
    I: 0,
    S: 0,
    C: 0,
  };

  // Iterate through user answers and update the DISC scores using forEach
  Object.entries(userAnswers).forEach(([index, answer]) => {
    const question = QUESTIONS[index];
    const answerScore = likertScale[answer];

    Object.entries(question.answers).forEach(([discType, diffValue]) => {
      scores[discType] += answerScore * parseFloat(diffValue);
    });
  });

  // Round the scores to 2 decimal places
  Object.entries(scores).forEach(([discType, score]) => {
    scores[discType] = Math.round(score * 100) / 100;
  });

  return scores;
};

const calculateAverageDISCScores = (userAnswers) => {
  // Define the Likert scale
  const likertScale = {
    Несогласен: 0,
    'Слегка несогласен': 1,
    'Нейтрально, без мнения': 2,
    'Слегка согласен': 3,
    Согласен: 4,
  };

  // Initialize the DISC scores
  const scores = {
    D: 0,
    I: 0,
    S: 0,
    C: 0,
  };

  Object.entries(userAnswers).forEach(([index, answer]) => {
    const question = QUESTIONS[index];
    const answerScore = likertScale[answer];

    let maxDiffValue = -Infinity;
    let maxDiffType = null;

    Object.entries(question.answers).forEach(([discType, diffValue]) => {
      const floatValue = parseFloat(diffValue);
      if (floatValue > maxDiffValue) {
        maxDiffValue = floatValue;
        maxDiffType = discType;
      }
    });

    if (maxDiffType) {
      scores[maxDiffType] += answerScore;
    }
  });

  // Calculate the scaled average scores for each type
  Object.entries(scores).forEach(([discType, score]) => {
    const scaledScore = (score * 25) / 4; // Scale the average score to match the weighted score range
    scores[discType] = Math.round(scaledScore * 100) / 100;
  });

  console.log(userAnswers);
  console.log(QUESTIONS.map((q) => q.question));

  return scores;
};

function ResultsTable({ answers }) {
  const weightedScores = calculateDISCScores(answers);
  const averageScores = calculateAverageDISCScores(answers);

  console.log([weightedScores, averageScores]);

  return (
    <Table striped bordered hover className="text-center">
      <thead>
        <tr>
          <th>Тип</th>
          <th>Значение</th>
          <th>Значение (average algorithm)</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(weightedScores).map(([key, value], index) => {
          // Get the corresponding average score for the current DISC type
          const averageScore = Object.values(averageScores)[index];

          return (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
              <td>{averageScore}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

ResultsTable.propTypes = {
  answers: PropTypes.object.isRequired,
};

function DISC() {
  const [answers, setAnswers] = React.useState({});
  const [finished, setFinished] = React.useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(-1);

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    if (value) {
      setAnswers({
        ...answers,
        [questionIndex]: answerIndex,
      });

      // Redirect to the next question
      if (questionIndex < QUESTIONS.length - 1) {
        setCurrentQuestionIndex(questionIndex + 1);
      }
    } else {
      setAnswers({
        ...answers,
        [questionIndex]: null,
      });
    }
  };

  return (
    <div>
      <h1>Тест DISC</h1>

      {
        currentQuestionIndex < 0 && (
          <>
            <p>
              Тест содержит 16 утверждений, с которыми вам необходимо выразить свою степень согласия,
              используя пятибалльную шкалу. Большинству людей требуется 10-15 минут для его завершения.
            </p>

            <Alert variant="warning">
              Дорогие коллеги, мы просим вас пройти этот тест и отвечать на вопросы честно и открыто.
              Помните, что нам важны лишь агрегированные результаты, а не конкретные ответы на вопросы
              (они хранятся в вашем браузере и только на время выполнения тесты).
              Честные ответы помогут нам лучше понять динамику нашей команды и сделать сотрудничество
              еще более продуктивным. Ваша откровенность позволит нам вместе расти и развиваться,
              создавая благоприятную атмосферу для каждого из нас. Спасибо за ваше участие!
            </Alert>

            <Button onClick={() => setCurrentQuestionIndex(0)}>Начать</Button>
          </>
        )
      }

      {
        currentQuestionIndex >= 0 && !finished && (
          <>
            <p className="lead">
              {`[${currentQuestionIndex + 1}/${QUESTIONS.length}] ${QUESTIONS[currentQuestionIndex].question}`}
            </p>

            <p className="border-start border-2 border-info px-2">
              {QUESTIONS[currentQuestionIndex].translation}
              <small className="d-block mt-2">
                Перевод автоматический, возможны неточности. Пожалуйста, не судите ChatGPT 4 строго :)
              </small>
            </p>

            {['Несогласен', 'Слегка несогласен', 'Нейтрально, без мнения', 'Слегка согласен', 'Согласен'].map((answer, index) => (
              <Form.Group className="mb-3" controlId={`${currentQuestionIndex}-${index}`} key={`${currentQuestionIndex}-${index}`}>
                <Form.Check
                  type="radio"
                  name={`question-${currentQuestionIndex}`}
                  label={answer}
                  onChange={(e) => handleAnswerChange(currentQuestionIndex, answer, e.target.checked)}
                  defaultChecked={answers[currentQuestionIndex] === answer}
                />
              </Form.Group>
            ))}

            {/* Buttons */}
            <div className="d-flex justify-content-between mt-4">
              <Button
                variant="secondary"
                onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
                disabled={currentQuestionIndex === 0}
              >
                Назад
              </Button>

              {
                currentQuestionIndex !== QUESTIONS.length - 1 && (
                <Button
                  variant="primary"
                  onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
                  disabled={currentQuestionIndex === QUESTIONS.length - 1}
                >
                  Далее
                </Button>
                )
              }

              {
                currentQuestionIndex === QUESTIONS.length - 1 && (
                <Button
                  variant="primary"
                  onClick={() => setFinished(true)}
                >
                  Завершить
                </Button>
                )
              }
            </div>
          </>
        )
      }

      {
        finished && (
          <>
            <h2 className="pb-2">Результаты</h2>

            <ResultsTable answers={answers} />

            <p>
              <Button
                variant="primary"
                onClick={() => {
                  const element = document.createElement('a');
                  const file = new Blob([JSON.stringify(calculateDISCScores(answers))], { type: 'text/plain' });
                  element.href = URL.createObjectURL(file);
                  element.download = 'results.json';
                  document.body.appendChild(element);
                  element.click();
                }}
              >
                Скачать
              </Button>

              {/* Download answers calculated by average algorithm */}
              <Button
                variant="primary"
                className="ms-2"
                onClick={() => {
                  const element = document.createElement('a');
                  const file = new Blob([JSON.stringify(calculateAverageDISCScores(answers, 'average'))], { type: 'text/plain' });
                  element.href = URL.createObjectURL(file);
                  element.download = 'results-average.json';
                  document.body.appendChild(element);
                  element.click();
                }}
              >
                Скачать (average algorithm)
              </Button>
            </p>

            <h4 className="mb-3">Характеристика типов:</h4>

            <Row className="border">
              <Col className="bg-danger p-2" style={{ '--bs-bg-opacity': 0.5 }}>
                <h5>Доминирующий тип (D):</h5>
                <ul>
                  <li>Уверенность в себе и решительность.</li>
                  <li>Лидерские качества и независимость.</li>
                  <li>Стремление достигать результатов быстро.</li>
                  <li>Способность принимать сложные решения.</li>
                  <li>Конкурентоспособность и амбициозность.</li>
                  <li>Фокус на результатах и эффективности.</li>
                  <li>Инициативность и настойчивость.</li>
                  <li>Склонность к риску и крупным задачам.</li>
                  <li>Умение мотивировать и вдохновлять окружающих.</li>
                  <li>Не терпит медлительности и нерешительности.</li>
                </ul>
              </Col>
              <Col className="bg-warning p-2" style={{ '--bs-bg-opacity': 0.5 }}>
                <h5>Социальный тип (I):</h5>
                <ul>
                  <li>Открытость и дружелюбие.</li>
                  <li>Харизма и обаяние.</li>
                  <li>Легко находит общий язык с людьми.</li>
                  <li>Оптимистичный и жизнерадостный.</li>
                  <li>Отличные коммуникативные навыки.</li>
                  <li>Любит быть в центре внимания.</li>
                  <li>Стремление к позитивным и радостным событиям.</li>
                  <li>Умение поддерживать и мотивировать окружающих.</li>
                  <li>Гибкость в общении и адаптация к разным ситуациям.</li>
                  <li>Энергичность и активность.</li>
                </ul>
              </Col>

              <Col className="bg-primary p-2" style={{ '--bs-bg-opacity': 0.5 }}>
                <h5>Стабильный тип (S):</h5>

                <ul>
                  <li>Спокойствие и терпение.</li>
                  <li>Преданность и лояльность.</li>
                  <li>Умение сотрудничать и поддерживать окружающих.</li>
                  <li>Ценит гармонию и стабильность.</li>
                  <li>Доброжелательность и забота о других.</li>
                  <li>Ответственность и надежность.</li>
                  <li>Приспособляемость к переменам и новым условиям.</li>
                  <li>Стремление к равновесию и комфорту.</li>
                  <li>Умение слушать и понимать других.</li>
                  <li>Не любит конфликты и противостояния.</li>
                </ul>
              </Col>

              <Col className="bg-success p-2" style={{ '--bs-bg-opacity': 0.5 }}>
                <h5>Созидательный тип (C):</h5>
                <ul>
                  <li>Точность и внимание к деталям.</li>
                  <li>Аналитическое и системное мышление.</li>
                  <li>Стремление к совершенству и высоким стандартам.</li>
                  <li>Ориентация на качество и результат.</li>
                  <li>Ответственность и пунктуальность.</li>
                  <li>Усидчивость и организованность.</li>
                  <li>Осторожность и преемственность в решениях.</li>
                  <li>Умение планировать и контролировать процессы.</li>
                  <li>Упорство и настойчивость в достижении целей.</li>
                  <li>Склонность к тщательному анализу и обдумыванию решений.</li>
                </ul>
              </Col>
            </Row>
          </>
        )
      }

    </div>
  );
}

export default DISC;
