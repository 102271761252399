import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, FloatingLabel, Form, FormControl, InputGroup } from 'react-bootstrap';
import { ANSWERS, COMPETENCIES, LEVELS, NM_ANSWERS } from '../data';

function groupCompetenciesByCategory(competencies) {
  const groupedCompetencies = {};

  competencies.forEach((competency) => {
    if (!groupedCompetencies[competency.category]) {
      groupedCompetencies[competency.category] = [];
    }

    groupedCompetencies[competency.category].push(competency);
  });

  return groupedCompetencies;
}

function getAnswerName(answerId, isNegativeMarker) {
  console.log(isNegativeMarker);
  const answers = isNegativeMarker ? NM_ANSWERS : ANSWERS;

  if (isNegativeMarker) {
    console.log(answerId);

    console.log(answers.find((a) => a.id === answerId));
  }

  return answers.find((a) => a.id === answerId)?.name;
}

function ReviewResults() {
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);

        // data should be an object, not an array
        if (Array.isArray(data)) {
          throw new Error('Invalid JSON file');
        }

        // Should be a mapping competencyId -> score
        Object.keys(data).forEach((competencyId) => {
          if (!COMPETENCIES.find((c) => c.id === competencyId)) {
            throw new Error(`Invalid competency ID: ${competencyId}`);
          }
        });

        // Replace answers for NM questions regarding the mapping
        // const OLD_NM_ANSWERS_MAPPING = {
        //   novice: 'never',
        //   beginner: 'rarely',
        //   intermediate: 'sometimes',
        //   advanced: 'often',
        //   expert: 'always',
        // };

        // Object.keys(data).forEach((competencyId) => {
        //   const competency = COMPETENCIES.find((c) => c.id === competencyId);
        //   if (competency.categoryId === 'NM') {
        //     data[competencyId] = OLD_NM_ANSWERS_MAPPING[data[competencyId]];
        //   }
        // });

        setResults(data);
      } catch (err) {
        setError(err);
        setResults(null);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div>
      <h1>Review Results</h1>

      <div className="border-start border-primary border-3 px-3 mb-5 mt-5 pb-4 pt-4">
        <p className="lead">Upload JSON file with results</p>

        <InputGroup>
          <FormControl
            type="file"
            onChange={handleFileUpload}
          />
        </InputGroup>

        {
          error && (
            <Alert variant="danger" className="mt-3">
              {error.message}
            </Alert>
          )
        }
      </div>

      {results && <ResultsTable results={results} />}
    </div>
  );
}

function ResultsTable({ results }) {
  const handleDownloadCSV = () => {
    const comments = JSON.parse(localStorage.getItem('REVIEW_COMMENTS')) || {};
    const header = 'Competency ID|Competency Name|Answer|Comment\n';
    const csv = Object.entries(results).map(([competencyId, answerId]) => {
      const competency = COMPETENCIES.find((c) => c.id === competencyId);
      const answer = competency.categoryId === 'NM'
        ? NM_ANSWERS.find((a) => a.id === answerId)
        : ANSWERS.find((a) => a.id === answerId);
      const comment = comments[competencyId] || '';

      return `${competencyId}|${competency.name}|${answer.name}|${comment}`;
    }).join('\n');

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(header + csv)}`);
    element.setAttribute('download', 'Review with comments.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <>

      <div className="border-start border-primary border-3 px-3 mb-5 mt-5 pb-4 pt-4">
        <p className="lead">Download results with comments in CSV format</p>
        <Button variant="primary" onClick={handleDownloadCSV}>Download CSV</Button>
      </div>

      {
        LEVELS.map((level, i) => (
          <React.Fragment key={i}>
            <div className="border-start border-warning border-3 px-3 mb-5 mt-5 pb-4 pt-4">
              <h2>{level.name}</h2>

              <p className="lead">
                {level.description}
              </p>

              <ul>
                {
                level.requirements.map((requirement, j) => (
                  <li key={j}>{requirement}</li>
                ))
              }
              </ul>

            </div>

            <div className="mb-5">
              {
              Object.entries(groupCompetenciesByCategory(COMPETENCIES.filter((c) => c.level === level.id))).map(([category, competencies]) => (
                <div className="mb-5" key={category}>
                  <h3>{category}</h3>

                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">Competency</th>
                        <th className="text-center" style={{ width: '15%' }}>Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        competencies.map((competency, k) => (
                          <tr key={k}>
                            <td className="p-3">
                              <strong>{`[${competency.id}] ${competency.name}`}</strong>
                              <br />
                              <br />
                              <small style={{ whiteSpace: 'pre-line' }}>{competency.description}</small>

                              <FloatingLabel controlId={`${competency.id}-comment`} label="Comments" className="mt-4 mb-4">
                                <Form.Control
                                  as="textarea"
                                  placeholder="Leave a comment here"
                                  style={{ height: '100px' }}
                                  defaultValue={JSON.parse(localStorage.getItem('REVIEW_COMMENTS'))?.[competency.id]}
                                  onChange={(e) => {
                                    // Write in localStorage comments
                                    const comments = JSON.parse(localStorage.getItem('REVIEW_COMMENTS')) || {};
                                    comments[competency.id] = e.target.value;
                                    localStorage.setItem('REVIEW_COMMENTS', JSON.stringify(comments));
                                  }}
                                />
                              </FloatingLabel>
                            </td>
                            <td className={`text-center p-3 ${results[competency.id]}`}>
                              <strong>
                                {getAnswerName(results[competency.id], competency.categoryId === 'NM')}
                              </strong>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ))
            }
            </div>
          </React.Fragment>
        ))
      }
    </>
  );
}

ResultsTable.propTypes = {
  results: PropTypes.object.isRequired,
};

export default ReviewResults;
